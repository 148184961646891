import { Button } from '@/components/ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { cn } from '@/lib/utils';
import { Check, ChevronsUpDown, LoaderCircle } from 'lucide-react';

type Option = {
  value: string;
  label: string;
};

type ComboboxProps = {
  isLoading?: boolean;
  options: Option[];
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  value: string | null;
  label?: string;
  dropDownLabel?: string;
  onChange: (value: string | null) => void;
  inputValue?: string;
  onInputChange: (value: string) => void;
};

const Combobox = ({
  isLoading,
  options,
  open,
  setOpen,
  value,
  inputValue,
  onInputChange,
  onChange,
  label = '',
  dropDownLabel = '',
}: ComboboxProps) => {
  const iconClassName = 'ml-2 h-4 w-4 shrink-0 opacity-50';

  return (
    <Popover open={open} onOpenChange={setOpen} modal>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          disabled={isLoading}
          className="w-full justify-between bg-white font-normal text-typography-secondary"
        >
          {value ? value : `請選擇${label}`}
          {isLoading ? (
            <LoaderCircle className={`${iconClassName} animate-spin`} />
          ) : (
            <ChevronsUpDown className={iconClassName} />
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className="p-0 w-[--radix-popover-trigger-width]"
        align="start"
        side="bottom"
        sideOffset={5}
      >
        <Command shouldFilter={false}>
          <CommandInput
            placeholder={`請輸入${dropDownLabel}`}
            value={inputValue}
            onValueChange={onInputChange}
          />
          <CommandList>
            <CommandEmpty>未找到選項</CommandEmpty>
            <CommandGroup>
              {options.map(option => (
                <CommandItem
                  className="h-10"
                  key={option.value}
                  value={option.value}
                  onSelect={() => {
                    onChange(option.value);
                    setOpen(false);
                  }}
                >
                  <Check
                    className={cn(
                      'mr-2 h-4 w-4',
                      value === option.value ? 'opacity-100' : 'opacity-0',
                    )}
                  />
                  {option.label}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

export default Combobox;
