import { useEffect, useState } from 'react';
import { MenuIcon } from 'lucide-react';
import { Sheet, SheetContent, SheetTrigger } from '@/components/ui/sheet';
import { SideNav } from '@/components/layout/side-nav';
import { useStore } from '@/store';
import { FolderClose } from '@/components/icons';

export const MobileSidebar = () => {
  const [open, setOpen] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const departments = useStore.home((state) => state.departments);
  const navItems =
    departments.map(({ id, name: title, departmentId }) => {
      return {
        title,
        icon: <FolderClose />,
        href: id,
        departmentId
      };
    }) ?? [];
  useEffect(() => {
    setIsMounted(true);
  }, []);

  if (!isMounted) {
    return null;
  }

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetTrigger asChild>
        <div className="flex items-center justify-center gap-2">
          <MenuIcon />
          <img className="w-36" src="/logo.svg" alt="docubank logo" />
        </div>
      </SheetTrigger>
      <SheetContent side="left" className="w-72 overflow-y-scroll">
        <div className="px-1 py-2">
          <SideNav items={navItems} setOpen={setOpen} />
        </div>
      </SheetContent>
    </Sheet>
  );
};
