import SettingLayout from '@/components/layout/setting-layout';
import { createFileRoute } from '@tanstack/react-router';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import { ColumnDef } from '@tanstack/react-table';
import { DialogActionType } from '@/constants';
import { Trash2 } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { DataTable } from '@/components/data-table';
import { toast } from '@/components/ui/use-toast';
import { useMutation, useQuery } from '@apollo/client';
import { PermissionsDocument, RemoveRoleDocument, RolesDocument } from '@@graphql';
import RoleDialog from '@/components/role-dialog.tsx';
import { useErrorHandler } from '@/hooks/useErrorHandler.tsx';
import { PermissionData } from '@/types';

type RoleData = {
  id?: string;
  name: string;
  description?: string;
  permission: number;
  canEdit: boolean;
  canRemove: boolean;
};

export const Route = createFileRoute('/_layout/settings/role-settings')({
  component: UserSettings,
});

function getPermissionNames(permissions: PermissionData[], permission: number): string {
  return permissions
    .filter(data => (data.value & permission) !== 0)
    .map(p => p.name)
    .join(', ');
}

function UserSettings() {
  const { handleError } = useErrorHandler({});
  const { data: permissionData } = useQuery(PermissionsDocument, {
    fetchPolicy: 'cache-first',
  });
  const { data, error, loading, refetch } = useQuery(RolesDocument, {
    fetchPolicy: 'network-only',
  });
  const [removeRoleMutation] = useMutation(RemoveRoleDocument, {
    onError: err => handleError(err),
    onCompleted: async () => {
      toast({
        title: '已刪除成功',
        duration: 1500,
      });
      await refetch();
    },
  });
  // loading and error handling
  if (loading) return null;
  if (error) return <div>Error: {error.message}</div>;

  const roleData =
    data?.roles.map(
      ({ id, name, permission, description, canEdit, canRemove }: RoleData) => {
        return {
          id,
          name,
          permission,
          description,
          canEdit,
          canRemove,
        } as RoleData;
      },
    ) ?? [];
  const { permissions } = permissionData;
  const roleColumns: ColumnDef<RoleData>[] = [
    {
      accessorKey: 'name',
      header: '名稱',
      cell: ({ row }) => row.getValue('name'),
    },
    {
      accessorKey: 'description',
      header: '描述',
      cell: ({ row }) => row.getValue('description'),
    },
    {
      accessorKey: 'permission',
      header: '權限',
      cell: ({ row }) => getPermissionNames(permissions, row.getValue('permission')),
    },
    {
      id: 'edit',
      header: '編輯',
      enableHiding: false,
      cell: ({ row }) => {
        const { name, permission, description, id, canEdit } = row.original;
        return (
          <RoleDialog
            id={id}
            type={DialogActionType.EDIT}
            name={name}
            description={description}
            permission={permission}
            permissionConfig={permissions}
            refetch={refetch}
            canEdit={canEdit}
          />
        );
      },
    },
    {
      id: 'actions',
      header: '操作',
      enableHiding: false,
      cell: ({ row }) => {
        const { name, id, canRemove } = row.original;
        return (
          <div>
            <AlertDialog>
              <AlertDialogTrigger asChild>
                <Button
                  className="size-8 text-error hover:text-error"
                  variant="ghost"
                  size="icon"
                  disabled={!canRemove}
                >
                  <Trash2 size={20} strokeWidth={1.5} />
                </Button>
              </AlertDialogTrigger>
              <AlertDialogContent>
                <AlertDialogHeader>
                  <AlertDialogTitle>刪除角色</AlertDialogTitle>
                  <AlertDialogDescription>
                    {`確定要刪除 ${name}`}
                  </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                  <AlertDialogCancel>取消</AlertDialogCancel>
                  <AlertDialogAction
                    onClick={async () => {
                      await removeRoleMutation({
                        variables: {
                          id,
                        },
                      });
                    }}
                  >
                    確定
                  </AlertDialogAction>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          </div>
        );
      },
    },
  ];
  return (
    <SettingLayout>
      <Card>
        <CardHeader>
          <div className="flex justify-between items-center">
            <CardTitle>角色權限管理</CardTitle>
            <RoleDialog
              permissionConfig={permissions}
              type={DialogActionType.CREATE}
              refetch={refetch}
              permission={permissions.reduce(
                (sum: number, item: PermissionData) => sum + item.value,
                0,
              )}
            />
          </div>
          <CardDescription>此頁面用來新增、編輯、刪除角色</CardDescription>
        </CardHeader>
        <CardContent className="space-y-2">
          <DataTable data={roleData} columns={roleColumns} />
        </CardContent>
      </Card>
    </SettingLayout>
  );
}
