import { ActionButton, ActionProps } from '@/types/edit-action.ts';
import { useParams } from '@tanstack/react-router';
import CopyForm from '@/pages/edit-actions/copy-form.tsx';
import { Duplicate } from '@/components/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { CompanyConfigDocument, CopyFilesDocument } from '@@graphql';
import { toast } from '@/components/ui/use-toast';
import { useErrorHandler } from '@/hooks/useErrorHandler.tsx';
import { useStore } from '@/store';
import { ACTION_TYPE_TITLE } from '@/constants';

export const DuplicateAction = ({
  selectedFileIds,
  refetchFolder,
  openDialog,
  closeDialog,
}: ActionProps): ActionButton => {
  const setCompanyConfig = useStore.companyConfig(state => state.setConfig);
  const [companyUsedSizeQuery] = useLazyQuery(CompanyConfigDocument);
  const { handleError } = useErrorHandler({});
  const { folderId: fileId } = useParams({ strict: false });
  const [copyToFolderMutation] = useMutation(CopyFilesDocument, {
    onCompleted: async () => {
      refetchFolder && (await refetchFolder());
      const { data } = await companyUsedSizeQuery({
        fetchPolicy: 'network-only',
      });
      if (data?.company) {
        setCompanyConfig({ ...data.company });
      }
      closeDialog();
    },
  });

  const handleDuplicate = () => {
    if (fileId) {
      openDialog(
        '複製',
        <CopyForm
          selectedFileIds={selectedFileIds}
          fileId={fileId}
          onSubmit={async ({ fileIds, targetFolderId }) => {
            try {
              await copyToFolderMutation({
                variables: {
                  fileIds,
                  targetFolderId,
                },
              });
              toast({
                title: '複製成功',
              });
            } catch (err) {
              handleError(err);
            }
          }}
        />,
      );
    }
  };

  return {
    title: ACTION_TYPE_TITLE.DUPLICATE,
    icon: <Duplicate />,
    onClick: handleDuplicate,
  };
};
