import { FormEvent, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { KeywordsDocument, UpdateDepartmentKeywordDocument } from '@@graphql';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
  DialogClose,
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { useErrorHandler } from '@/hooks/useErrorHandler';
import { createPromptSchema } from '@/zodSchema/create-prompt';
import { toast } from '@/components/ui/use-toast';
import { Label } from '@/components/ui/label.tsx';
import InputTags from '@/components/InputTags.tsx';

interface UpdatePromptDialogProps {
  departmentId: string;
  departmentPromptId: string;
  onPromptCreated: (departmentId: string) => void;
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
}

const UpdatePromptDialog = ({
  departmentId,
  departmentPromptId,
  onPromptCreated,
  isOpen,
  onOpenChange,
}: UpdatePromptDialogProps) => {
  const { errors, handleError, resetErrors } = useErrorHandler({ keywords: '' });
  const [keywords, setKeywords] = useState<string[]>([]);
  const [initialKeywords, setInitialKeywords] = useState<string[]>([]);

  const handleCloseDialog = () => {
    resetErrors();
    setKeywords([]);
    setInitialKeywords([]);
    onOpenChange(false);
  };

  const [updateDepartmentKeywordMutation] = useMutation(
    UpdateDepartmentKeywordDocument,
    {
      onCompleted: () => {
        onPromptCreated(departmentId);
        toast({ title: '編輯文件分類規則成功', duration: 1500 });
        handleCloseDialog();
      },
    },
  );
  const [getKeywords] = useLazyQuery(KeywordsDocument, {
    fetchPolicy: 'network-only',
  });

  const onFormSubmitUpdateKeywords = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      resetErrors();
      createPromptSchema.parse({ keywords });
      await updateDepartmentKeywordMutation({
        variables: { departmentPromptId, keywords },
      });
    } catch (err: unknown) {
      handleError(err);
    }
  };

  const handleTagsChange = (newTags: string[]) => {
    setKeywords(newTags);
    setInitialKeywords(newTags);
  };

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[425px]">
        <form onSubmit={onFormSubmitUpdateKeywords}>
          <DialogHeader>
            <DialogTitle>編輯文件分類規則</DialogTitle>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="keyword" className="text-right font-normal">
                關鍵字
              </Label>
              <InputTags
                key={initialKeywords.join(',')}
                keywordsQuery={getKeywords}
                defaultTags={keywords}
                maxTags={4}
                onTagsChange={handleTagsChange}
              />
              {errors.keywords && <div className="text-red-500">{errors.keywords}</div>}
            </div>
          </div>
          <DialogFooter>
            <DialogClose asChild>
              <Button variant="outline" type="button">
                取消
              </Button>
            </DialogClose>
            <Button type="submit">儲存</Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default UpdatePromptDialog;
