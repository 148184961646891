const AVAILABLE_COLUMNS = [
  'selector',
  'name',
  'size',
  'creationInfo',
  'updatedAt',
  'departmentName',
] as const;

type AvailableColumnKeys = (typeof AVAILABLE_COLUMNS)[number];
export const defaultColumnKeys: FileColumnConfig = [
  'selector',
  'name',
  'size',
  'creationInfo',
  'updatedAt',
];

export type FileColumnConfig = AvailableColumnKeys[];
