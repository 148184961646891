import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Textarea } from '@/components/ui/textarea';
import { toast } from '@/components/ui/use-toast';
import { useEffect, useRef, useState } from 'react';
import { ApolloQueryResult, useMutation, useQuery } from '@apollo/client';
import {
  FileOcrHistoriesDocument,
  FileOcrHistoryModel,
  GetFileQuery,
  GetFileQueryVariables,
  RestoreFileOcrDocument,
  UpdateFIleOcrDocument,
} from '@@graphql';
import { Link, useLocation } from '@tanstack/react-router';
import { useErrorHandler } from '@/hooks/useErrorHandler.tsx';
import { Card } from '@/components/ui/card.tsx';
import { transFormDate } from '@/utils/file.ts';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog.tsx';

interface OcrViewerProps {
  ocrText?: string | undefined;
  fileId: string;
  refetchFile?:
    | ((
        variables?: Partial<GetFileQueryVariables>,
      ) => Promise<ApolloQueryResult<GetFileQuery>>)
    | undefined;
}

interface OcrHistoriesProps extends Pick<OcrViewerProps, 'fileId'> {
  onRestore: (text: string) => void;
}

const OcrHistories = ({ fileId, onRestore }: OcrHistoriesProps) => {
  const [openDialog, setOpenDialog] = useState(false);
  const { handleError } = useErrorHandler({});
  const { data, error, loading, refetch } = useQuery(FileOcrHistoriesDocument, {
    variables: { fileId },
    fetchPolicy: 'cache-first',
  });

  const [restoreOcrMutation] = useMutation(RestoreFileOcrDocument, {
    onCompleted: async data => {
      await refetch({ fileId });
      toast({
        title: '更新成功',
        description: '檔案 OCR 內容已更新',
      });
      setOpenDialog(false);
      onRestore(data.restoreFileOcr.ocrText);
    },
    onError: error => handleError(error),
  });
  const [selectedHistory, setSelectedHistory] = useState<FileOcrHistoryModel | null>(
    null,
  );

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;
  const histories = data?.file.fileOcrHistories as FileOcrHistoryModel[];
  const handleCardClick = (history: FileOcrHistoryModel) => {
    setSelectedHistory(history);
    setOpenDialog(true);
  };
  const handleRestore = async () => {
    if (selectedHistory) {
      await restoreOcrMutation({
        variables: {
          fileId,
          fileOcrHistoryId: selectedHistory.id,
        },
      });
    }
  };
  return (
    <div className="mt-2 space-y-2">
      {histories.map(history => {
        return (
          <Card
            key={history.id}
            className="hover:bg-primary-100 cursor-pointer flex items-start p-6"
            onClick={() => handleCardClick(history)}
          >
            <div className="flex-shrink-0 w-8 h-8 bg-blue-500 rounded-full flex items-center justify-center text-white font-bold text-sm mr-3">
              {history.fileOcrEditorName.charAt(0).toUpperCase() || '?'}
            </div>
            <div className="flex-grow min-w-0">
              <div className="flex items-center text-sm">
                <span className="font-medium">{history.fileOcrEditorName}</span>
                <span className="mx-1 text-gray-500">•</span>
                <span className="text-gray-500">
                  {transFormDate(history.createdAt)}
                </span>
              </div>
              <p className="mt-1 text-sm text-gray-700 truncate">{history.ocrText}</p>
            </div>
          </Card>
        );
      })}
      <Dialog open={openDialog} onOpenChange={setOpenDialog}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>OCR 歷史紀錄</DialogTitle>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <b className="text-primary">更新者</b>
            <div>{selectedHistory?.fileOcrEditorName}</div>
            <b className="text-primary">更新時間</b>
            <div>{transFormDate(selectedHistory?.createdAt)}</div>
            <b className="text-primary">OCR 內容</b>
            <div className="max-h-[200px] overflow-y-auto">
              {selectedHistory?.ocrText}
            </div>
          </div>
          <Button onClick={handleRestore}>復原成此 OCR</Button>
        </DialogContent>
      </Dialog>
    </div>
  );
};

const FormSchema = z.object({
  ocrText: z
    .string()
    .min(1, {
      message: 'Ocr 文字送出至少要 1 個字',
    })
    .max(65536, {
      message: 'Ocr 文字不能超過 65536 字',
    }),
});

function OcrViewer({ ocrText, fileId, refetchFile }: OcrViewerProps) {
  const location = useLocation();
  const [expandHistoryBlock, setExpandHistoryBlock] = useState(false);
  const { handleError } = useErrorHandler({});
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  const [updateFIleOcrMutation] = useMutation(UpdateFIleOcrDocument, {
    onCompleted: () => {
      refetchFile?.({ fileId });
      toast({
        title: '更新成功',
        description: '檔案OCR內容已更新',
      });
    },
  });

  useEffect(() => {
    const adjustHeight = () => {
      if (textareaRef.current) {
        const textareaRect = textareaRef.current.getBoundingClientRect();
        const topPosition = textareaRect.top;
        const viewportHeight = window.innerHeight;
        const newHeight = viewportHeight - topPosition - 50;

        textareaRef.current.style.height = `${newHeight}px`;
        textareaRef.current.style.overflowY = 'auto';
      }
    };
    requestAnimationFrame(adjustHeight);
    window.addEventListener('resize', adjustHeight);

    return () => {
      window.removeEventListener('resize', adjustHeight);
    };
  }, [expandHistoryBlock]);

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      ocrText,
    },
  });

  const handleRestore = (restoredText: string) => {
    form.setValue('ocrText', restoredText);
    setExpandHistoryBlock(false);
  };

  async function onSubmit(data: z.infer<typeof FormSchema>) {
    try {
      await updateFIleOcrMutation({
        variables: {
          fileId,
          ocrText: data.ocrText,
        },
      });
      toast({
        title: '已經更新Ocr文字',
      });
    } catch (err) {
      handleError(err);
    }
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="px-12 space-y-4 lg:px-2">
        {!expandHistoryBlock ? (
          <FormField
            control={form.control}
            name="ocrText"
            render={({ field }) => (
              <FormItem>
                <div className="flex justify-between items-center mb-2">
                  <FormLabel className="text-base font-medium">檔案OCR內容</FormLabel>
                  <Link
                    className="text-sm text-muted-foreground hover:text-primary"
                    onClick={e => {
                      e.preventDefault();
                      setExpandHistoryBlock(!expandHistoryBlock);
                    }}
                  >
                    查看 OCR 歷史紀錄
                  </Link>
                </div>
                <FormControl>
                  <Textarea
                    placeholder="此為OCR內容，不一定100%正確，可以自行調整"
                    className="overflow-hidden resize-none"
                    {...field}
                    ref={e => {
                      field.ref(e);
                      textareaRef.current = e;
                    }}
                  />
                </FormControl>
                <FormDescription>
                  OCR 文字是由檔案內容自動辨識出來的文字，不代表100%正確，請自行調整
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
        ) : (
          <div>
            <div className="flex justify-between items-center mb-2">
              <FormLabel className="text-base font-medium">OCR 歷史紀錄</FormLabel>
              <Link
                className="text-sm text-muted-foreground hover:text-primary cursor-pointer"
                onClick={e => {
                  e.preventDefault();
                  setExpandHistoryBlock(false);
                }}
              >
                返回編輯
              </Link>
            </div>
            <OcrHistories fileId={fileId} onRestore={handleRestore} />
          </div>
        )}
        {!location.pathname.startsWith('/trash') && !expandHistoryBlock && (
          <div className="text-right">
            <Button type="submit">儲存</Button>
          </div>
        )}
      </form>
    </Form>
  );
}

export default OcrViewer;
