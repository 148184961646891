import { useMemo } from 'react';
import { Folder } from 'lucide-react';
import { DepartmentPromptsDocument, FileModel } from '@@graphql';
import { ColumnDef } from '@tanstack/react-table';
import { Option } from '@/components/ui/multiple-selector';
import EmptyData from '@/components/empty-data';
import { DataTable } from '@/components/data-table.tsx';
import { useQuery } from '@apollo/client';
import UpdateNotificationDialog from '@/pages/settings/department-settings/arrival-management/update-notification-dialog';
import MultipleSelector from '@/components/ui/multiple-selector';

interface NotificationGroup {
  id: string;
  keywords: string;
  enabled: boolean;
  userIds: Option[];
  users: UserModel[];
}

interface ArrivalManagementProps {
  departmentId: string;
  isDepartmentClassification: boolean;
  firstLevelFolders: FileModel[];
}

const ArrivalManagement = ({
  departmentId,
  isDepartmentClassification,
  firstLevelFolders,
}: ArrivalManagementProps) => {
  const {
    data: DepartmentPromptsData,
    loading,
    refetch,
  } = useQuery(DepartmentPromptsDocument, {
    fetchPolicy: 'cache-and-network',
    variables: {
      departmentId,
    },
    skip: !departmentId,
  });

  const departmentPrompts = DepartmentPromptsData?.departmentPrompts;

  const columns = useMemo<ColumnDef<NotificationGroup>[]>(
    () => [
      {
        accessorKey: 'keywords',
        header: '關鍵字',
      },
      {
        id: 'enabled',
        header: '是否啟用',
        cell: ({ row }) => {
          const isEnabled = row.original.enabled;
          return <div>{isEnabled ? '是' : '否'}</div>;
        },
      },
      {
        id: 'users',
        header: '通知名單',
        cell: ({ row }) => {
          const { users } = row.original;
          return users.length === 0 ? (
            <div>沒有通知名單</div>
          ) : (
            <MultipleSelector
              className="w-[450px] border-none p-0"
              value={users.map(user => ({
                label: `${user.name} (${user.email})`,
                value: user.id,
              }))}
              hideClearAllButton
              hidePlaceholderWhenSelected
              disabled
            />
          );
        },
      },
      {
        id: 'edit',
        header: '編輯',
        cell: ({ row }) => {
          const { id, keywords } = row.original;
          return (
            <UpdateNotificationDialog
              departmentPromptId={id}
              departmentId={departmentId}
              keywords={keywords}
              refetch={refetch}
            />
          );
        },
      },
    ],
    [departmentId, refetch],
  );

  const renderDataTable = (data: NotificationGroup[]) => (
    <DataTable data={data} columns={columns} />
  );

  const renderFolderTable = () => {
    if (firstLevelFolders.length === 0) {
      return <EmptyData title="目前沒有資料夾" />;
    }

    return (
      <div className="space-y-6">
        {firstLevelFolders.map(({ id, name }) => {
          const folderKeywords = departmentPrompts.filter(
            data => data.topFolder?.id === id,
          );
          return (
            <div key={id} className="p-4 pt-3 border rounded-lg">
              <div className="flex justify-between items-center mb-3">
                <div className="flex items-center gap-2">
                  <Folder size={20} strokeWidth={1.5} />
                  {name}
                </div>
              </div>
              {renderDataTable(folderKeywords)}
            </div>
          );
        })}
      </div>
    );
  };

  if (loading) return null;

  return (
    <div>
      {isDepartmentClassification
        ? renderDataTable(departmentPrompts)
        : renderFolderTable()}
    </div>
  );
};

export default ArrivalManagement;
