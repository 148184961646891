import dayjs from 'dayjs';
import { Lock, LockOpen } from 'lucide-react';
import { useRouter } from '@tanstack/react-router';
import { DATE_TIME_FORMAT } from '@/constants';
import { cn } from '@/lib/utils';
import { FileModel } from '@@graphql';
import { formatFileSize, transformFileType } from '@/utils/file.ts';
import { Button } from '@/components/ui/button';
import { FolderClose } from '@/components/icons';

const FileInfoPanel = ({
  data,
  canControlPrivate,
  changePrivate,
}: {
  data: FileModel;
  canControlPrivate: boolean;
  changePrivate: () => Promise<void>;
}) => {
  const router = useRouter();
  const { ext, size, createdAt, updatedAt, breadcrumbs, isPrivate } = data;
  return (
    <div>
      <p className="mb-4 text-base font-medium">一般資訊</p>
      <ul className="flex flex-col gap-2 text-sm text-gray-400">
        <li className="flex">
          <p className="w-1/2 ">類型</p>
          <p className="">{ext ? transformFileType(ext) : ''}</p>
        </li>
        <li className="flex">
          <p className="w-1/2 ">大小</p>
          <p className="">{size ? formatFileSize(size) : ''}</p>
        </li>
        <li className="flex">
          <p className="w-1/2 ">檔案位置</p>
          <Button
            className="font-normal text-primary-dark text-sm p-0 h-auto underline"
            variant="link"
            onClick={() => {
              router.navigate({
                to: `/folders/${breadcrumbs[breadcrumbs.length - 1]?.id}`,
              });
            }}
          >
            <FolderClose width={19} height={16} />
            <p className="ml-1"> {breadcrumbs[breadcrumbs.length - 1]?.name}</p>
          </Button>
        </li>
        <li className="flex">
          <p className="w-1/2">上次修改時間</p>
          <p className="">{dayjs(updatedAt).format(DATE_TIME_FORMAT)}</p>
        </li>
        <li className="flex">
          <p className="w-1/2">建立時間</p>
          <p className="">{dayjs(createdAt).format(DATE_TIME_FORMAT)}</p>
        </li>
      </ul>
      {canControlPrivate && (
        <>
          <div className="border-b border-b-gray-200 py-4"></div>
          <p className="my-4 text-base font-medium">隱私設定</p>
          <Button
            variant="outline"
            size="sm"
            className={cn('flex items-center gap-1 bg-white')}
            onClick={changePrivate}
          >
            {isPrivate ? (
              <Lock className="h-4 w-4" />
            ) : (
              <LockOpen className="h-4 w-4" />
            )}
            {isPrivate ? '取消機敏文件' : '設為機敏文件'}
          </Button>
        </>
      )}
    </div>
  );
};

export default FileInfoPanel;
