import { Download } from '@/components/icons';
import { useFileMutation } from '@/tanstack';
import { ActionButton, ActionProps } from '@/types/edit-action.ts';
import { downloadFile, getFilenameFromContentDisposition } from '@/utils/file.ts';
import { toast } from '@/components/ui/use-toast';
import { ACTION_TYPE_TITLE } from '@/constants';

export const DownloadAction = ({
  selectedFileIds = [],
  openDialog,
  closeDialog,
}: ActionProps): ActionButton => {
  const { mutate } = useFileMutation({
    method: 'POST',
    path: '/api/download',
  });

  const handleDownload = () => {
    mutate(
      { fileIds: selectedFileIds },
      {
        onSuccess: ({ headers, blob }) => {
          openDialog('下載', <div>正在下載...</div>);
          const contentDisposition = headers.get('Content-Disposition');
          const filename = getFilenameFromContentDisposition(contentDisposition);
          downloadFile(blob, filename);
        },
        onError: err => {
          toast({
            title: '下載失敗',
            description: err?.message || '發生錯誤',
            duration: 1500,
          });
        },
        onSettled: () => {
          setTimeout(() => {
            closeDialog();
          }, 1000);
        },
      },
    );
  };

  return {
    title: ACTION_TYPE_TITLE.DOWNLOAD,
    icon: <Download />,
    onClick: handleDownload,
  };
};
