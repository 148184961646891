import { createFileRoute } from '@tanstack/react-router';
import { useLazyQuery, useQuery } from '@apollo/client';
import {
  BreadcrumbsDocument,
  FoldersDocument,
  RoleModel,
  TimeFieldEnum,
} from '@@graphql';
import FolderView from '@/pages/folderView.tsx';
import { BreadcrumbModel, FileModel } from '@/types';
import { fileModelToRealFileModel } from '@/utils/file.ts';
import { useAuth } from '@/auth.tsx';
import { useCallback, useEffect, useRef, useState } from 'react';
import InfiniteScrollWrapper from '@/components/infinite-scroll-wrapper.tsx';
import { useStore } from '@/store';
import DatePickerWithRange from '@/components/date-picker-with-range';
import { DateRange } from 'react-day-picker';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select.tsx';
import { SearchMime, TAKE_PER_PAGE } from '@/constants';
import { useTableStore } from '@/components/data-table';
import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button.tsx';

export const Route = createFileRoute('/_layout/folders/$folderId')({
  component: FileComponent,
});

function FileComponent() {
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const currentPageRef = useRef<number>(1);
  const [searchMime, setSearchMime] = useState<string>(SearchMime.ALL);
  const [fileRawData, setFileRawData] = useState<FileModel[]>([]);
  const [currentTimeField, setCurrentTimeField] = useState<TimeFieldEnum | undefined>(
    undefined,
  );
  const [createdDateRange, setCreatedDateRange] = useState<DateRange | undefined>(
    undefined,
  );
  const [updatedDateRange, setUpdatedDateRange] = useState<DateRange | undefined>(
    undefined,
  );
  const { user } = useAuth();
  const getCompanyConfig = useStore.companyConfig(state => state.getConfigValue);
  const { folderId: fileId } = Route.useParams();
  const { selectedFileIds, table } = useTableStore(state => state);
  const { data: breadcrumbs } = useQuery(BreadcrumbsDocument, {
    variables: { fileId },
    fetchPolicy: 'network-only',
  });

  const [fetchFolders, { loading, error, data }] = useLazyQuery(FoldersDocument, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    let ignore = false;

    const fetchData = async () => {
      const startAt =
        currentTimeField === TimeFieldEnum.CreatedAt
          ? createdDateRange?.from
          : updatedDateRange?.from;
      const endAt =
        currentTimeField === TimeFieldEnum.CreatedAt
          ? createdDateRange?.to
          : updatedDateRange?.to;
      const result = await fetchFolders({
        variables: {
          fileId,
          timeField: currentTimeField,
          timeRange: { startAt, endAt },
          mime: searchMime,
          take: TAKE_PER_PAGE,
        },
      });
      if (!ignore && result.data) {
        setFileRawData(result.data?.folders.items as FileModel[]);
        currentPageRef.current = 2;
      }
    };

    fetchData();

    return () => {
      ignore = true;
    };
  }, [
    fileId,
    createdDateRange,
    updatedDateRange,
    searchMime,
    currentTimeField,
    fetchFolders,
  ]);

  const loadMore = useCallback(async () => {
    if (isLoadingMore && !data?.folders.pageInfo.hasNextPage) return;

    setIsLoadingMore(true);
    const result = await fetchFolders({
      variables: {
        fileId,
        page: currentPageRef.current,
        take: TAKE_PER_PAGE,
        timeField: currentTimeField,
        timeRange: {
          startAt:
            currentTimeField === TimeFieldEnum.CreatedAt
              ? createdDateRange?.from
              : updatedDateRange?.from,
          endAt:
            currentTimeField === TimeFieldEnum.CreatedAt
              ? createdDateRange?.to
              : updatedDateRange?.to,
        },
        mime: searchMime,
      },
    });
    setIsLoadingMore(false);
    if (result.data) {
      setFileRawData(prev => [...prev, ...(result.data?.folders.items as FileModel[])]);
      currentPageRef.current += 1;
    }
    return result;
  }, [
    data?.folders.pageInfo.hasNextPage,
    fileId,
    fetchFolders,
    currentTimeField,
    createdDateRange,
    updatedDateRange,
    searchMime,
    isLoadingMore,
  ]);

  const refetchFolders = useCallback(async () => {
    currentPageRef.current = 1;
    setFileRawData([]);
    const result = await fetchFolders({
      variables: {
        fileId,
        page: 1,
        take: TAKE_PER_PAGE,
        timeField: currentTimeField,
        timeRange: {
          startAt:
            currentTimeField === TimeFieldEnum.CreatedAt
              ? createdDateRange?.from
              : updatedDateRange?.from,
          endAt:
            currentTimeField === TimeFieldEnum.CreatedAt
              ? createdDateRange?.to
              : updatedDateRange?.to,
        },
        mime: searchMime,
      },
    });
    if (result.data) {
      setFileRawData(result.data?.folders.items as FileModel[]);
      currentPageRef.current = 2;
    }
  }, [
    fileId,
    fetchFolders,
    currentTimeField,
    createdDateRange,
    updatedDateRange,
    searchMime,
  ]);

  if (!breadcrumbs?.breadcrumbs) return null;
  if (loading && currentPageRef.current === 1) return null;
  if (error) return <div>Error: {error.message}</div>;
  if (!user) return <div>User not authenticated</div>;

  const breadcrumbData = breadcrumbs.breadcrumbs as BreadcrumbModel[];
  const isDepartment = breadcrumbData.length === 2;
  const departmentId = isDepartment ? breadcrumbData.at(1)?.id : undefined;
  const managerPermissions = getCompanyConfig('roles')
    .filter(role => role.isManager)
    .map(role => role.permission);
  const canModifyDepartment =
    user.isAdmin ||
    Boolean(
      user.assignments?.find(
        assignment =>
          assignment.department.id === departmentId &&
          managerPermissions.includes(assignment.role.permission),
      ),
    );

  const currentDepartmentRole = user?.assignments?.find(
    assignment => assignment.department.id === departmentId,
  )?.role;

  const showConfidentialFiles =
    user?.isAdmin ||
    getCompanyConfig('roles').find(role => role.id === currentDepartmentRole?.id)
      ?.canControlPrivateFile;

  const folderName = breadcrumbData ? breadcrumbData.at(-1)?.name : undefined;
  return (
    <InfiniteScrollWrapper
      hasMore={data?.folders?.pageInfo.hasNextPage || false}
      loadMore={loadMore}
      isLoading={isLoadingMore && currentPageRef.current !== 1}
    >
      <FolderView
        refetchFolder={refetchFolders}
        filterAction={
          <>
            <DatePickerWithRange
              date={createdDateRange}
              setDate={setCreatedDateRange}
              onSelect={() => {
                setCurrentTimeField(TimeFieldEnum.CreatedAt);
                setUpdatedDateRange(undefined);
              }}
              placeholder="建立時間"
            />
            <DatePickerWithRange
              date={updatedDateRange}
              setDate={setUpdatedDateRange}
              onSelect={() => {
                setCurrentTimeField(TimeFieldEnum.UpdatedAt);
                setCreatedDateRange(undefined);
              }}
              placeholder="上次修改時間"
            />
            <Select onValueChange={setSearchMime}>
              <SelectTrigger className="w-[110px] focus:ring-transparent bg-background hover:bg-accent hover:text-accent-foreground">
                <SelectValue placeholder="類型" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value={SearchMime.ALL}>所有類型</SelectItem>
                <SelectItem value={SearchMime.PDF}>PDF</SelectItem>
                <SelectItem value={SearchMime.IMAGE}>圖片</SelectItem>
                <SelectItem value={SearchMime.OFFICE}>Office 相關</SelectItem>
              </SelectContent>
            </Select>
            <div
              className={cn(
                'flex items-center text-sm text-muted-foreground gap-2 invisible',
                selectedFileIds.length > 0 && 'visible',
              )}
            >
              <div>目前選擇 {selectedFileIds.length} 筆</div>
              <Button
                className="px-2 font-normal text-primary"
                variant="ghost"
                onClick={() => {
                  table.toggleAllRowsSelected(false);
                }}
              >
                <p>取消所有選擇</p>
              </Button>
            </div>
          </>
        }
        folderName={folderName}
        isDepartment={isDepartment}
        canModifyDepartment={canModifyDepartment}
        breadcrumbs={breadcrumbData}
        files={fileModelToRealFileModel(fileRawData)}
        departmentId={departmentId}
        companyId={user.company!.id}
        canControlConfidentialFiles={showConfidentialFiles}
      />
    </InfiniteScrollWrapper>
  );
}
