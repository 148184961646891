import React, { useState } from 'react';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { ScanRegionEnum, SetFtpScanRegionDocument } from '@@graphql';
import { QueryOptions, useMutation } from '@apollo/client';
import { useErrorHandler } from '@/hooks/useErrorHandler.tsx';
import { toast } from '@/components/ui/use-toast';

const GRID_REGIONS = [
  [ScanRegionEnum.TopLeft, ScanRegionEnum.TopRight],
  [ScanRegionEnum.MiddleLeft, ScanRegionEnum.MiddleRight],
  [ScanRegionEnum.BottomLeft, ScanRegionEnum.BottomRight],
] as const;

export enum CodePositionType {
  QRCODE = 'QR Code',
  BARCODE = 'Barcode',
}

type CodePositionSettingProps = {
  ftpAccountId?: string;
  type?: CodePositionType;
  readonly?: boolean;
  defaultRegion?: ScanRegionEnum;
  refetchQueries?: QueryOptions<{ [key: string]: string }>[];
};

type RegionCellProps = {
  isSelected: boolean;
  readonly: boolean;
  type: CodePositionType;
  onClick: () => void;
};

const RegionCell = ({ isSelected, readonly, type, onClick }: RegionCellProps) => (
  <div
    className={cn(
      'flex flex-col items-center justify-center w-full h-auto bg-primary-100 border border-primary-200 hover:bg-primary-200',
      isSelected && 'border-4 border-primary',
      readonly && 'cursor-not-allowed hover:bg-primary-100',
    )}
    onClick={() => !readonly && onClick()}
  >
    {isSelected && (
      <div className="text-center text-xs">
        <div>{type}</div>
        <div>位於此範圍內</div>
      </div>
    )}
  </div>
);

const CodePositionSetting = ({
  ftpAccountId,
  type = CodePositionType.QRCODE,
  readonly = false,
  defaultRegion = ScanRegionEnum.TopRight,
  refetchQueries,
}: CodePositionSettingProps) => {
  const [selectedRegion, setSelectedRegion] = useState<ScanRegionEnum>(defaultRegion);
  const { handleError } = useErrorHandler({});

  const [setFtpScanRegion] = useMutation(SetFtpScanRegionDocument, {
    refetchQueries,
    onCompleted: () => {
      toast({
        title: '儲存 QR Code 位置成功',
        duration: 1500,
      });
    },
    onError: err => {
      handleError(err);
      toast({
        title: '儲存 QR Code 位置失敗',
        duration: 1500,
      });
    },
  });

  return (
    <div className="grid gap-6">
      {!readonly && (
        <>
          <div className="text-lg">{`❷ 設定 ${type} 位置`}</div>
          <div className="text-sm">
            {`請點選下方區塊並儲存，將會依據範圍內的位置進行 ${type}
        判斷，進行${type === CodePositionType.QRCODE ? '文件分類' : '文件分割'}。`}
          </div>
        </>
      )}
      <div className="grid grid-cols-2 grid-rows-3 w-[250px] h-[350px] border border-primary-200 cursor-pointer">
        {GRID_REGIONS.map((row, rowIndex) => (
          <React.Fragment key={rowIndex}>
            {row.map(region => (
              <RegionCell
                key={region}
                isSelected={selectedRegion === region}
                readonly={readonly}
                type={type}
                onClick={() => setSelectedRegion(region)}
              />
            ))}
          </React.Fragment>
        ))}
      </div>
      {!readonly && (
        <div className="flex justify-end space-x-2">
          <Button
            disabled={!ftpAccountId}
            onClick={() =>
              setFtpScanRegion({
                variables: {
                  ftpAccountId,
                  scanRegion: selectedRegion,
                },
              })
            }
          >
            儲存位置
          </Button>
        </div>
      )}
    </div>
  );
};

export default CodePositionSetting;
