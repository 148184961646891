import { useState, useEffect } from 'react';
import { useMutation, QueryOptions } from '@apollo/client';
import { RenewFtpAccountCredentialsDocument } from '@@graphql';
import * as XLSX from 'xlsx';
import dayjs from 'dayjs';
import { useErrorHandler } from '@/hooks/useErrorHandler.tsx';
import { toast } from '@/components/ui/use-toast';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog.tsx';
import { Label } from './ui/label';
import { Input } from './ui/input';
import { Button } from '@/components/ui/button';
import CopyButton from '@/components/copy-button';

type FtpSettingProps = {
  ftpAccountId?: string;
  account: string;
  password?: string;
  refetchQueries: QueryOptions<{ [key: string]: string }>[];
};

const FtpSetting = ({
  ftpAccountId,
  account,
  password,
  refetchQueries,
}: FtpSettingProps) => {
  const { handleError } = useErrorHandler({});
  const [currentPassword, setCurrentPassword] = useState(password);

  const [RenewFtpAccountCredentials] = useMutation(RenewFtpAccountCredentialsDocument, {
    refetchQueries,
    onCompleted: ({ renewFtpAccountCredentials }) => {
      setCurrentPassword(renewFtpAccountCredentials.plainPassword);
      toast({
        title: '已重新產生 FTP 帳號密碼',
        duration: 1500,
      });
    },
    onError: err => {
      handleError(err);
      toast({
        title: '重新產生 FTP 帳號密碼失敗',
        duration: 1500,
      });
    },
  });

  useEffect(() => {
    setCurrentPassword(password);
  }, [password]);

  return (
    <div className="grid gap-6">
      <div className="text-lg">❶ 設定 FTP</div>
      <div className="text-sm">
        {currentPassword ? (
          <div>
            請複製以下的帳號和密碼至事務機設定 FTP 資料，
            <span className="text-red-500">密碼僅能檢視與下載一次，請務必保管密碼</span>
            。
          </div>
        ) : (
          <>
            密碼僅在建立帳號時提供，如果忘記密碼，可以點選重新產生帳密的按鈕，再使用新的帳號密碼至事務機設定。
          </>
        )}
      </div>
      <div className="grid gap-2 w-[300px]">
        <Label htmlFor="account">FTP 伺服器位址</Label>
        <div className="flex items-center gap-1">
          <Input
            className="bg-gray-100"
            id="account"
            value={import.meta.env.VITE_FTP_PUBLIC_HOST}
            disabled
          />
          <CopyButton value={import.meta.env.VITE_FTP_PUBLIC_HOST} />
        </div>
      </div>
      <div className="grid gap-2 w-[300px]">
        <Label htmlFor="account">帳號</Label>
        <div className="flex items-center gap-1">
          <Input className="bg-gray-100" id="account" value={account} disabled />
          <CopyButton value={account} />
        </div>
      </div>
      {currentPassword && (
        <div className="grid gap-2 w-[300px]">
          <Label htmlFor="password">密碼</Label>
          <div className="flex items-center gap-1">
            <Input
              className="bg-gray-100"
              type="password"
              id="password"
              value={currentPassword}
              disabled
            />
            <CopyButton value={currentPassword} />
          </div>
        </div>
      )}
      <div className="flex justify-end space-x-2">
        <AlertDialog>
          <AlertDialogTrigger asChild>
            <Button variant="outline" disabled={!ftpAccountId}>
              重新產生帳密
            </Button>
          </AlertDialogTrigger>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>重新產生帳密</AlertDialogTitle>
              <AlertDialogDescription>
                <span className="text-red-600">
                  注意：重新產生帳密後，原先事務機設定將會失效，需要再次設定。
                </span>
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel>取消</AlertDialogCancel>
              <AlertDialogAction
                onClick={() =>
                  RenewFtpAccountCredentials({
                    variables: {
                      ftpAccountId,
                    },
                  })
                }
              >
                確認
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
        {currentPassword && (
          <Button
            onClick={() => {
              const now = dayjs().format('YYYY-MM-DD HH-mm');
              const data = [
                {
                  帳號: account,
                  密碼: currentPassword,
                },
              ];
              const ws = XLSX.utils.json_to_sheet(data);
              const wb = XLSX.utils.book_new();
              XLSX.utils.book_append_sheet(wb, ws, 'FTP 密碼');
              XLSX.writeFile(wb, `FTP 密碼-${now}.xlsx`);
            }}
          >
            下載密碼
          </Button>
        )}
      </div>
    </div>
  );
};

export default FtpSetting;
