import SettingLayout from '@/components/layout/setting-layout';
import { createFileRoute } from '@tanstack/react-router';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import { ColumnDef } from '@tanstack/react-table';
import { DataTable } from '@/components/data-table';
import { ArrowUpDown, Trash2 } from 'lucide-react';
import { Button } from '@/components/ui/button';
import WhitelistDialog from '@/components/whitelist-dialog';
import { useMutation, useQuery } from '@apollo/client';
import { MfpAllowEmailsDocument, RemoveMfpAllowEmailDocument } from '@@graphql';
import { toast } from '@/components/ui/use-toast';
import { useEffect, useState } from 'react';
import { AllowEmailType } from '@/types/allow-email.ts';

type WhitelistData = {
  id: string;
  email: string;
  description: string;
  type: string;
};

export const Route = createFileRoute('/_layout/settings/whitelist-settings')({
  component: WhiteList,
});

function WhiteList() {
  const [canRemove, setCanRemove] = useState(true);
  const { data, loading, error, refetch } = useQuery(MfpAllowEmailsDocument, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    setCanRemove(true);
    if (data?.mfpAllowEmails.length <= 1) {
      setCanRemove(false);
    }
  }, [data?.mfpAllowEmails]);

  const [removeEmailMutation] = useMutation(RemoveMfpAllowEmailDocument, {
    onCompleted: async () => {
      await refetch();
    },
  });
  if (loading) return <div>loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  const emails = data?.mfpAllowEmails.map(
    ({
      id,
      email,
      description,
      type,
    }: {
      id: string;
      email: string;
      description?: string;
      type: string;
    }) => {
      return {
        id,
        email,
        description,
        type,
      };
    },
  ) as WhitelistData[];

  const whitelistColumns: ColumnDef<WhitelistData>[] = [
    {
      accessorKey: 'email',
      header: ({ column }) => {
        return (
          <div
            className="flex items-center cursor-pointer"
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            電子郵件
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </div>
        );
      },
      cell: ({ row }) => (
        <div className="font-mono text-sm">{row.getValue('email')}</div>
      ),
    },
    {
      accessorKey: 'type',
      header: '類型',
      cell: ({ row }) => {
        const type = row.getValue('type');
        if (type === AllowEmailType.MFP) {
          return '事務機';
        }
        return type;
      },
    },
    {
      accessorKey: 'description',
      header: '說明',
      cell: ({ row }) => row.getValue('description'),
    },
    {
      id: 'actions',
      header: '刪除',
      enableHiding: false,
      cell: ({ row }) => {
        const { email, id } = row.original;
        return (
          <div>
            <AlertDialog>
              <AlertDialogTrigger asChild>
                <Button
                  className="size-8 text-error hover:text-error"
                  variant="ghost"
                  size="icon"
                  disabled={!canRemove}
                >
                  <Trash2 size={20} strokeWidth={1.5} />
                </Button>
              </AlertDialogTrigger>
              <AlertDialogContent>
                <AlertDialogHeader>
                  <AlertDialogTitle>刪除使用者</AlertDialogTitle>
                  <AlertDialogDescription>
                    {`確定要刪除白名單 ${email}？`}
                  </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                  <AlertDialogCancel>取消</AlertDialogCancel>
                  <AlertDialogAction
                    onClick={async () => {
                      await removeEmailMutation({
                        variables: { id },
                      });
                      toast({
                        title: '刪除成功',
                        duration: 1500,
                      });
                    }}
                  >
                    確定
                  </AlertDialogAction>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          </div>
        );
      },
    },
  ];

  return (
    <SettingLayout>
      <Card>
        <CardHeader>
          <div className="flex justify-between items-center">
            <CardTitle>寄件人白名單管理</CardTitle>
            <WhitelistDialog refetch={refetch} />
          </div>
          <CardDescription>
            此頁面用來設定寄件人白名單，只有白名單中的寄件人才可以寄信給自動分類信箱
          </CardDescription>
        </CardHeader>
        <CardContent className="space-y-2">
          <DataTable data={emails} columns={whitelistColumns} />
        </CardContent>
      </Card>
    </SettingLayout>
  );
}
