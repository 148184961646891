import dayjs from 'dayjs';
import { cn } from '@/lib/utils';
import { DATE_FORMAT } from '@/constants';
import { AlarmClockCheck, CircleX } from 'lucide-react';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { Button } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';

const ReminderPanel = ({
  isOpen,
  expectedAt,
  onChangeCalendar,
  setIsOpen,
}: {
  isOpen: boolean;
  expectedAt: Date | undefined;
  onChangeCalendar: (date?: Date) => Promise<void>;
  setIsOpen: (open: boolean) => void;
}) => {
  return (
    <>
      <Popover open={isOpen} onOpenChange={setIsOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            className={cn(
              'w-[160px] pl-3 text-left font-normal',
              expectedAt ? 'text-muted-foreground bg-primary-100' : 'bg-white',
            )}
          >
            {expectedAt ? (
              dayjs(expectedAt).format(DATE_FORMAT)
            ) : (
              <span>請選擇提醒時間</span>
            )}
            {expectedAt ? (
              <CircleX
                className="ml-auto h-4 w-4 opacity-50"
                onClick={() => onChangeCalendar(undefined)}
              />
            ) : (
              <AlarmClockCheck className="ml-auto h-4 w-4 opacity-50" />
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar
            mode="single"
            selected={expectedAt}
            onSelect={onChangeCalendar}
            disabled={date => date < new Date()}
            initialFocus
          />
        </PopoverContent>
      </Popover>
      <p className="mt-2 text-sm text-muted-foreground hidden lg:block">
        系統將在提醒日期當天的 00:00 自動發送電子郵件通知
      </p>
    </>
  );
};

export default ReminderPanel;
