import { useMutation } from '@apollo/client';
import { RemoveFilesDocument } from '@@graphql';
import { Delete } from '@/components/icons';
import { DialogClose, DialogDescription, DialogFooter } from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { ActionButton, ActionProps } from '@/types/edit-action.ts';
import { useErrorHandler } from '@/hooks/useErrorHandler.tsx';
import { ACTION_TYPE_TITLE } from '@/constants';
import { toast } from '@/components/ui/use-toast';

export const DeleteAction = ({
  selectedFileIds = [],
  refetchFolder,
  openDialog,
  closeDialog,
}: ActionProps): ActionButton => {
  const { handleError } = useErrorHandler({});
  const [removeFiles] = useMutation(RemoveFilesDocument, {
    onError: err => handleError(err),
    onCompleted: async () => {
      toast({
        title: '刪除成功',
      });
      refetchFolder && (await refetchFolder());
      closeDialog();
    },
  });

  const handleDelete = async () => {
    openDialog(
      '刪除檔案',
      <div>
        <DialogDescription className="text-sm">
          你確定要刪除選取的檔案嗎？
        </DialogDescription>
        <DialogDescription className="text-sm">
          若選取的是資料夾，則會刪除資料夾內所有檔案
        </DialogDescription>
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline" type="button">
              取消
            </Button>
          </DialogClose>
          <Button
            variant="destructive"
            type="button"
            onClick={() => {
              removeFiles({
                variables: { fileIds: selectedFileIds },
              });
            }}
          >
            確定刪除
          </Button>
        </DialogFooter>
      </div>,
    );
  };

  return {
    title: ACTION_TYPE_TITLE.DELETE,
    icon: <Delete />,
    onClick: handleDelete,
  };
};
