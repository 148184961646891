import { createFileRoute } from '@tanstack/react-router';
import { Button } from '@/components/ui/button.tsx';
import { Card, CardContent } from '@/components/ui/card.tsx';
import { Input } from '@/components/ui/input.tsx';
import { Label } from '@/components/ui/label.tsx';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { ToggleMfaSettingDocument, UserMfaSettingDocument } from '@@graphql';
import { toast } from '@/components/ui/use-toast';
import ProfileLayout from '@/components/layout/profile-layout.tsx';
import { Info } from 'lucide-react';
import { Switch } from '@/components/ui/switch.tsx';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog.tsx';
import { useErrorHandler } from '@/hooks/useErrorHandler.tsx';

// ... PasswordInput component remains the same

function MfaSettings() {
  const { handleError } = useErrorHandler({});
  const { data: mfaSetting, loading } = useQuery(UserMfaSettingDocument, {
    fetchPolicy: 'network-only',
  });
  const [toggleMfaMutation] = useMutation(ToggleMfaSettingDocument);
  const [isEmailOtpEnabled, setIsEmailOtpEnabled] = useState(false);
  const [showPasswordConfirmDialog, setShowPasswordConfirmDialog] = useState(false);
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [isEnabling, setIsEnabling] = useState(false);
  useEffect(() => {
    if (mfaSetting?.userMfaSetting) {
      setIsEmailOtpEnabled(!!mfaSetting.userMfaSetting.otpEnabledAt);
    }
  }, [mfaSetting]);

  if (loading) return <div>Loading...</div>;
  const handleMfaSettingToggle = () => {
    setIsEnabling(!isEmailOtpEnabled);
    setShowPasswordConfirmDialog(true);
  };

  const confirmEmailOtpToggle = async () => {
    try {
      await toggleMfaMutation({
        variables: {
          enable: isEnabling,
          password: passwordConfirmation,
        },
      });
      setIsEmailOtpEnabled(isEnabling);
      toast({
        title: 'Success',
        description: `Email OTP ${isEnabling ? '啟用' : '停用'}成功`,
        duration: 1500,
      });
    } catch (err) {
      handleError(err);
    } finally {
      setShowPasswordConfirmDialog(false);
      setPasswordConfirmation('');
    }
  };

  return (
    <ProfileLayout>
      <Card className="w-full md:w-1/3 py-6 px-2">
        <form>
          <CardContent className="space-y-2 w-full">
            <h2 className="font-bold text-md pt-3">Email OTP 設定</h2>
            <div className="flex items-center space-x-2">
              <Switch
                id="email-otp"
                checked={isEmailOtpEnabled}
                onCheckedChange={handleMfaSettingToggle}
              />
              <Label htmlFor="email-otp">啟用 Email OTP 驗證</Label>
            </div>
            <div className="inline-flex items-center px-3 py-2 bg-blue-50 rounded-md text-blue-700">
              <Info className="w-5 h-5 mr-2" />
              <p className="text-sm">
                啟用後，每次登錄時系統將發送一次性驗證碼到您的郵箱
              </p>
            </div>
          </CardContent>
        </form>
      </Card>

      <Dialog
        open={showPasswordConfirmDialog}
        onOpenChange={setShowPasswordConfirmDialog}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>確認密碼</DialogTitle>
          </DialogHeader>
          <div className="space-y-4">
            <p>請輸入您的當前密碼以{isEnabling ? '啟用' : '停用'} Email OTP 驗證</p>
            <Input
              type="password"
              value={passwordConfirmation}
              onChange={e => setPasswordConfirmation(e.target.value)}
              placeholder="輸入密碼"
            />
          </div>
          <DialogFooter>
            <Button onClick={confirmEmailOtpToggle}>確認</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </ProfileLayout>
  );
}

export const Route = createFileRoute('/_layout/profile/2fa-settings')({
  component: MfaSettings,
});
