import { ActionButton, ActionProps } from '@/types/edit-action';
import { PinOff } from 'lucide-react';
import { useErrorHandler } from '@/hooks/useErrorHandler';
import { useMutation } from '@apollo/client';
import { UnpinFilesDocument } from '@@graphql';
import { toast } from '@/components/ui/use-toast';
import { DialogClose, DialogFooter } from '@/components/ui/dialog.tsx';
import { Button } from '@/components/ui/button.tsx';
import { ACTION_TYPE_TITLE } from '@/constants';

export const UnpinAction = ({
  selectedFileIds,
  openDialog,
  closeDialog,
  refetchFolder,
}: ActionProps): ActionButton => {
  const { handleError } = useErrorHandler({});
  const [mutation] = useMutation(UnpinFilesDocument, {
    onCompleted: async () => {
      refetchFolder && (await refetchFolder());
      closeDialog();
    },
  });
  const ConfirmModal = () => {
    const executePinFiles = async () => {
      try {
        await mutation({ variables: { fileIds: selectedFileIds } });
      } catch (error) {
        handleError(error);
      }
    };
    return (
      <div>
        <div className="grid gap-4 py-4">確認要取消置頂所選的檔案嗎？</div>
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline" type="button">
              取消
            </Button>
          </DialogClose>
          <Button type="button" onClick={executePinFiles}>
            確定
          </Button>
        </DialogFooter>
      </div>
    );
  };

  const handleUnpin = async () => {
    if (selectedFileIds.length > 1) {
      toast({
        title: '取消置頂失敗',
        description: '一次只能夠取消置頂一個檔案',
        duration: 1500,
      });
      return;
    }
    openDialog('取消置頂檔案', <ConfirmModal />);
  };

  return {
    title: ACTION_TYPE_TITLE.UNPIN,
    icon: <PinOff />,
    onClick: handleUnpin,
  };
};
