import { createFileRoute } from '@tanstack/react-router';
import { useQuery } from '@apollo/client';
import { FilesDocument } from '@@graphql';
import { RealFileModel } from '@/types';
import { fileModelToRealFileModel } from '@/utils/file.ts';
import FolderView from '@/pages/folderView.tsx';
import { useEffect, useState } from 'react';

export const Route = createFileRoute('/_layout/trash/')({
  component: Trash,
});

function Trash() {
  const [files, setFiles] = useState<RealFileModel[]>([]);
  const { data, error, loading, refetch } = useQuery(FilesDocument, {
    variables: { inTrash: true, departmentId: 'all' },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (data?.files) {
      setFiles(fileModelToRealFileModel(data.files));
    }
  }, [data]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;
  const refetchFolder = async (variables: any) => {
    await refetch(variables);
  };
  return (
    <FolderView
      folderName="資源回收桶"
      isDepartment={false}
      breadcrumbs={[]}
      refetchFolder={refetchFolder}
      files={files}
    />
  );
}
