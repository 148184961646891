import { MobileSidebar } from '@/components/layout/mobile-sidebar';
import { UserNav } from '@/components/layout/user-nav';
import { Button } from '@/components/ui/button';
import { useState } from 'react';
import { Search } from '@/components/icons';
import { useAuth } from '@/auth.tsx';
import { SearchBar } from '@/components/ui/search-bar.tsx';

export default function Header() {
  const auth = useAuth();
  const { user } = auth;
  const [open, setOpen] = useState(false);

  return (
    <div
      className="supports-backdrop-blur:bg-background/60 fixed left-0 right-0 top-0 z-20 border-b bg-background/95 backdrop-blur bg-white">
      <nav className="flex h-16 items-center justify-between px-4">
        <a
          href={'/'}
          className="hidden items-center justify-between gap-2 md:flex"
        >
          <img className="w-36" src="/logo.svg" alt="docubank logo" />
        </a>
        <div className="block md:hidden">
          <MobileSidebar />
        </div>
        <SearchBar
          open={open}
          onOpenChange={setOpen}
        />
        <div className="flex items-center gap-5 ">
          <div
            className="cursor-pointer block md:hidden"
            onClick={() => setOpen(true)}
          >
            <Search />
          </div>
          {user ? (
            <UserNav user={user} />
          ) : (
            <Button size="sm">
              登入
            </Button>
          )}
        </div>
      </nav>
    </div>
  );
}
