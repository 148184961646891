import { X } from 'lucide-react';
import { PanelItem } from '@/pages/file-view';
import { Button } from '@/components/ui/button';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import { PanelId } from './index';

type FileHeaderProps = {
  name: string;
  panelData: PanelItem[];
  onOpenPanel: () => void;
  setCurrentPanelId: (id: PanelId) => void;
  hasPreviousPage: boolean;
  onBack: () => void;
};

export const FileHeader = ({
  name,
  panelData,
  onOpenPanel,
  setCurrentPanelId,
  hasPreviousPage,
  onBack,
}: FileHeaderProps) => (
  <header className="flex items-center h-[64px] border-b shadow-sm sticky top-0 bg-white">
    {hasPreviousPage && (
      <Button className="ml-4" variant="ghost" size="icon" onClick={() => onBack()}>
        <X />
      </Button>
    )}
    <p className="ml-4 text-lg font-medium">{name}</p>
    <div className="flex-1 flex justify-end px-4 gap-2 text-muted-foreground">
      {panelData.map(button => (
        <Tooltip key={button.title}>
          <TooltipTrigger asChild>
            <Button
              className="size-8 text-typography-secondary hover:bg-primary-100 hover:text-typography-secondary"
              variant="ghost"
              size="icon"
              onClick={() => {
                setCurrentPanelId(button.id as PanelId);
                onOpenPanel();
              }}
            >
              {button.icon}
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            <p>{button.title}</p>
          </TooltipContent>
        </Tooltip>
      ))}
    </div>
  </header>
);
