import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog.tsx';
import { Trash2 } from 'lucide-react';
import { useMutation, QueryOptions } from '@apollo/client';
import { RemoveFtpAccountDocument } from '@@graphql';
import { useErrorHandler } from '@/hooks/useErrorHandler.tsx';
import { toast } from '@/components/ui/use-toast';
import { Button } from '@/components/ui/button.tsx';

type RemoveFtpDialogProps = {
  id?: string;
  departmentName?: string;
  refetchQueries: QueryOptions<{ [key: string]: string }>[];
};

const RemoveFtpDialog = ({
  id,
  departmentName,
  refetchQueries,
}: RemoveFtpDialogProps) => {
  const { handleError } = useErrorHandler({});

  const [removeFtpAccount] = useMutation(RemoveFtpAccountDocument, {
    refetchQueries,
    onCompleted: () => {
      toast({
        title: '刪除 FTP 帳號成功',
        duration: 1500,
      });
    },
    onError: err => {
      handleError(err);
      toast({
        title: '刪除 FTP 帳號失敗',
        duration: 1500,
      });
    },
  });

  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button
          variant="ghost"
          disabled={!id}
          className="flex items-center gap-1 text-red-600 cursor-pointer hover:bg-transparent hover:text-red-600 pl-0 text-md font-normal"
        >
          <Trash2 size={20} strokeWidth={1.5} />
          {`刪除${departmentName ? '部門' : '公司'} FTP 帳號`}
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{`刪除${departmentName || '公司'} FTP 帳號`}</AlertDialogTitle>
          <AlertDialogDescription>
            <span className="text-red-600">
              注意：確認刪除 FTP 帳號後，原先事務機設定將會失效，需要再次設定。
            </span>
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>取消</AlertDialogCancel>
          <AlertDialogAction
            onClick={() =>
              removeFtpAccount({
                variables: {
                  ftpAccountId: id,
                },
              })
            }
          >
            確定刪除
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default RemoveFtpDialog;
