import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Button } from '@/components/ui/button';
import { UserRoundPlus } from 'lucide-react';
import { Label } from './ui/label';
import { RoleModel, User } from '@/types';
import { useEffect, useState } from 'react';
import { SetUserToDepartmentDocument, UsersDocument } from '@@graphql';
import { useLazyQuery, useMutation } from '@apollo/client';
import { toast } from '@/components/ui/use-toast';
import { ucfirst } from '@/utils';
import { useErrorHandler } from '@/hooks/useErrorHandler.tsx';
import Combobox from './combobox';
import useDebounce from '@/hooks/useDebounce.ts';
import { useAuth } from '@/auth.tsx';

type UsersOptionType = {
  id: string;
  value: string;
  label: string;
};

type DepartmentPermissionDialogProps = {
  roles: RoleModel[];
  departmentId: string;
  refetchUsers: () => unknown;
};

const DepartmentPermissionDialog = ({
  roles,
  departmentId,
  refetchUsers,
}: DepartmentPermissionDialogProps) => {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState<string | undefined>('');
  const currentUser = useAuth().user as User;
  const [selectedRoleId, setSelectedRoleId] = useState<string>('');
  const [openCombobox, setOpenCombobox] = useState(false);
  const [usersOptions, setUsersOptions] = useState<UsersOptionType[]>([]);
  const [selectedUser, setSelectedUser] = useState<{
    email: string;
    id: string;
  } | null>(null);

  const errorObject = { email: '', roleId: '' };
  const debouncedName = useDebounce(name, 500);
  const [getUsersQuery, { loading: isUsersLoading }] = useLazyQuery(UsersDocument, {
    fetchPolicy: 'network-only',
    onError: err => handleError(err),
  });

  useEffect(() => {
    if (departmentId && openCombobox) {
      getUsersQuery({
        variables: {
          departmentId,
          name: { startsWith: debouncedName },
        },
        onCompleted: usersData => {
          const users = usersData?.users || [];
          const options = users
            .filter((user: User) => user.id !== currentUser.id)
            .map(({ email, id, name }: User) => ({
              id,
              value: email,
              label: `${name} (${email})`,
            }));
          setUsersOptions(options);
        },
      });
    }
  }, [currentUser.id, debouncedName, departmentId, getUsersQuery, openCombobox]);

  const { errors, handleError, resetErrors } = useErrorHandler(errorObject);
  const [setUser] = useMutation(SetUserToDepartmentDocument, {
    onCompleted: async () => {
      await refetchUsers();
    },
  });
  const resetDialogState = (newOpen: boolean) => {
    setName('');
    setSelectedRoleId('');
    setSelectedUser(null);
    setUsersOptions([]);
    setOpenCombobox(false);
    setOpen(newOpen);
  };

  const handleSave = async () => {
    resetErrors();
    if (!selectedRoleId || !selectedUser) {
      toast({
        title: '請設定權限與使用者',
        duration: 1500,
        variant: 'destructive',
      });
      return;
    }

    try {
      await setUser({
        variables: {
          departmentId,
          roleId: selectedRoleId,
          userId: selectedUser.id,
        },
      });
      setSelectedUser(null);
      setName('');
      setSelectedRoleId('');
      setOpen(false);
      toast({
        title: `成功將 ${selectedUser.email} 綁定到部門`,
        duration: 1500,
      });
    } catch (err) {
      handleError(err);
    }
  };

  const handleOnChange = (value: string | null) => {
    setSelectedUser(null);
    const selected = usersOptions.find(option => option.value === value);
    if (selected) {
      setSelectedUser({ email: selected.value, id: selected.id });
    }
  };

  return (
    <Dialog open={open} onOpenChange={resetDialogState}>
      <DialogTrigger asChild>
        <Button className="gap-1 font-normal text-primary" variant="ghost">
          <UserRoundPlus size={20} strokeWidth={1.5} /> 新增使用者
        </Button>
      </DialogTrigger>
      <DialogContent className="max-w-[450px]">
        <DialogHeader>
          <DialogTitle>新增使用者</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="email" className="text-right font-normal">
              電子郵件
            </Label>
            <div className="col-span-3">
              <Combobox
                isLoading={isUsersLoading}
                options={usersOptions}
                open={openCombobox}
                setOpen={setOpenCombobox}
                value={selectedUser?.email || null}
                onChange={handleOnChange}
                inputValue={name}
                onInputChange={setName}
                label="電子郵件"
                dropDownLabel="使用者名稱"
              />
            </div>
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="role" className="text-right font-normal">
              權限
            </Label>
            <div className="col-span-3">
              <Select value={selectedRoleId} onValueChange={setSelectedRoleId}>
                <SelectTrigger className="col-span-3 focus:ring-transparent text-typography-secondary">
                  <SelectValue placeholder="請選擇權限" />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    {roles.map(role => (
                      <SelectItem key={role.id} value={role.id}>
                        {ucfirst(role.name)}
                      </SelectItem>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
            {errors.roleId && (
              <div className="text-red-500 text-sm">{errors.roleId}</div>
            )}
          </div>
        </div>
        <DialogFooter>
          <DialogClose asChild>
            <Button
              variant="outline"
              type="button"
              onClick={() => {
                resetDialogState(false);
              }}
            >
              取消
            </Button>
          </DialogClose>
          <Button
            type="submit"
            onClick={async e => {
              e.preventDefault();
              await handleSave();
            }}
          >
            儲存
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
export default DepartmentPermissionDialog;
