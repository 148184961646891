import {
  ComponentType,
  lazy,
  LazyExoticComponent,
  Suspense,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from 'react';
import { useFileQuery } from '@/tanstack';
import { useRouter } from '@tanstack/react-router';
import { Sparkles, AlarmClockCheck, History, Info } from 'lucide-react';
import {
  GetFileQuery,
  GetFileQueryVariables,
  SetFileExpiredAtDocument,
  ToggleFilesPrivateDocument,
  FileModel,
} from '@@graphql';
import { ApolloQueryResult, useMutation } from '@apollo/client';
import { useWindowSize } from '@react-hook/window-size';
import dayjs from 'dayjs';
import { cn } from '@/lib/utils';
import GeneratePleadingDialog from '../generate-pleading-dialog';
import OcrViewer from '@/components/viewer/ocr-viewer.tsx';
import { TooltipProvider } from '@/components/ui/tooltip';
import { LegalType } from '@/constants/files';
import { useStore } from '@/store';
import { useAuth } from '@/auth';
import ReminderPanel from './reminder-panel';
import FileInfoPanel from './file-info-panel';
import { FileHeader } from './file-header';
import { SidePanel } from './side-panel';
import Loading from '@/components/loading';

type FileViewProps = {
  data: FileModel;
  fileId: string;
  refetchFile?: (
    variables?: Partial<GetFileQueryVariables>,
  ) => Promise<ApolloQueryResult<GetFileQuery>>;
};

export enum PanelId {
  GENERATE = 'generate',
  REMINDER = 'reminder',
  HISTORY = 'history',
  INFO = 'info',
}

export type PanelItem = {
  id: string;
  title: string;
  icon: React.ReactNode;
  content: React.ReactNode;
};

export type FileViewerProps = {
  fileUrl?: string;
  file?: Blob | undefined;
};

export type ViewerComponentType = LazyExoticComponent<ComponentType<FileViewerProps>>;

const importComponent = (ext?: string) => {
  if (!ext) return lazy(() => import('@/components/viewer/unknown-viewer.tsx'));
  const pureExt = ext.replace('.', '').toLowerCase();
  const imageTypes = ['gif', 'jpeg', 'png', 'bmp', 'webp', 'jpg'];
  if (imageTypes.includes(pureExt))
    return lazy(
      () =>
        import('@/components/viewer/image-viewer.tsx') as Promise<{
          default: ComponentType<FileViewerProps>;
        }>,
    );
  if (pureExt === 'pdf')
    return lazy(
      () =>
        import('@/components/viewer/pdf-viewer.tsx') as Promise<{
          default: ComponentType<FileViewerProps>;
        }>,
    );
  return lazy(
    () =>
      import('@/components/viewer/unknown-viewer.tsx') as Promise<{
        default: ComponentType<FileViewerProps>;
      }>,
  );
  //   todo other types
};

const FileView = ({ data, fileId, refetchFile }: FileViewProps) => {
  const { name, fileUrl, fileOcr, legalType, createdAt, expired, isPrivate } = data;
  const router = useRouter();
  const { user } = useAuth();
  const [width] = useWindowSize();
  const getCompanyConfig = useStore.companyConfig(state => state.getConfigValue);
  const roles = getCompanyConfig('roles');
  const defaultExpectedAt = expired ? dayjs(expired?.expectedAt).toDate() : undefined;
  const [ViewerComponent, setViewerComponent] = useState<ViewerComponentType | null>(
    null,
  );
  const [isMobile, setIsMobile] = useState(false);
  const [panelOpen, setPanelOpen] = useState(false);
  const [currentPanelId, setCurrentPanelId] = useState<string | null>(null);
  const [expectedAt, setExpectedAt] = useState<Date | undefined>(defaultExpectedAt);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [setFileExpiredAt] = useMutation(SetFileExpiredAtDocument);
  const [setFilePrivate] = useMutation(ToggleFilesPrivateDocument);

  const onChangeCalendar = useCallback(
    async (date: Date | undefined) => {
      if (date) setCalendarOpen(false);
      setExpectedAt(date);
      await setFileExpiredAt({
        variables: {
          fileId,
          expectedAt: date ? dayjs(date).format() : null,
        },
      });
    },
    [fileId, setFileExpiredAt],
  );

  const canGeneratePleading =
    user?.isAdmin ||
    Boolean(
      user?.assignments?.find(
        assignment =>
          roles.find(role => role.canCreateComplaint)?.id === assignment.role.id,
      ),
    );

  const hasPleading =
    legalType === LegalType.LEGAL_PLEADING_COURT ||
    legalType === LegalType.LEGAL_PLEADING_AED;

  const canControlPrivate =
    user?.isAdmin ||
    Boolean(
      user?.assignments?.find(
        assignment =>
          roles.find(role => role.canControlPrivateFile)?.id === assignment.role.id,
      ),
    );

  useEffect(() => {
    setIsMobile(width <= 576);
  }, [width]);

  const { data: fileInfo } = useFileQuery(data.fileUrl);

  const panelData = useMemo(() => {
    return [
      ...(hasPleading && canGeneratePleading && !isMobile
        ? [
            {
              id: PanelId.GENERATE,
              title: 'AI 生成',
              icon: <Sparkles strokeWidth={1.2} />,
              content: (
                <>
                  <GeneratePleadingDialog fileId={fileId} createdAt={createdAt} />
                  <p className="mt-2 text-sm text-muted-foreground hidden lg:block">
                    透過 AI 生成結果，未必 100% 準確，請查證生成內容
                  </p>
                </>
              ),
            },
          ]
        : []),
      ...(isMobile
        ? []
        : [
            {
              id: PanelId.REMINDER,
              title: '設定提醒時間',
              icon: <AlarmClockCheck strokeWidth={1.2} />,
              content: (
                <ReminderPanel
                  isOpen={calendarOpen}
                  setIsOpen={setCalendarOpen}
                  expectedAt={expectedAt}
                  onChangeCalendar={onChangeCalendar}
                />
              ),
            },
            {
              id: PanelId.HISTORY,
              title: 'OCR 歷史紀錄',
              icon: <History strokeWidth={1.2} />,
              content: (
                <OcrViewer
                  fileId={fileId}
                  refetchFile={refetchFile}
                  ocrText={fileOcr?.ocrText}
                />
              ),
            },
          ]),
      {
        id: PanelId.INFO,
        title: '詳細資料',
        icon: <Info strokeWidth={1.2} />,
        content: (
          <FileInfoPanel
            data={data}
            canControlPrivate={canControlPrivate}
            changePrivate={async () => {
              await setFilePrivate({
                variables: {
                  fileIds: [fileId],
                  isPrivate: !isPrivate,
                },
              });
              refetchFile?.({ fileId });
            }}
          />
        ),
      },
    ];
  }, [
    calendarOpen,
    canControlPrivate,
    canGeneratePleading,
    createdAt,
    data,
    expectedAt,
    fileId,
    fileOcr?.ocrText,
    hasPleading,
    isMobile,
    isPrivate,
    onChangeCalendar,
    refetchFile,
    setFilePrivate,
  ]);

  useEffect(() => {
    setViewerComponent(() => importComponent(data.ext as string));
  }, [data.ext]);

  if (!ViewerComponent) return null;

  return (
    <Suspense fallback={<Loading />}>
      <TooltipProvider delayDuration={0}>
        <FileHeader
          name={name}
          panelData={panelData}
          onOpenPanel={() => setPanelOpen(true)}
          setCurrentPanelId={setCurrentPanelId}
          hasPreviousPage={router.history.length > 2}
          onBack={() => router.history.back()}
        />
        <SidePanel
          isOpen={panelOpen}
          onClose={() => setPanelOpen(false)}
          currentPanelId={currentPanelId}
          panelData={panelData}
        />
      </TooltipProvider>
      <section className={isMobile && panelOpen ? 'hidden' : ''}>
        <div className="grid grid-cols-1 lg:grid-cols-4">
          <div
            className={cn(
              'col-span-1 lg:col-span-4 mt-4',
              panelOpen && 'lg:col-span-3',
            )}
          >
            <ViewerComponent file={fileInfo} fileUrl={fileUrl} />
          </div>
        </div>
      </section>
    </Suspense>
  );
};

export default FileView;
