import { createFileRoute } from '@tanstack/react-router';
import { useLazyQuery } from '@apollo/client';
import { FileMimeEnum, SearchFilesDocument } from '@@graphql';
import { RealFileModel, User } from '@/types';
import { fileModelToRealFileModel } from '@/utils/file.ts';
import FolderView from '@/pages/folderView.tsx';
import { useCallback, useEffect, useRef, useState } from 'react';
import { SearchScope, TAKE_PER_PAGE } from '@/constants';
import InfiniteScrollWrapper from '@/components/infinite-scroll-wrapper.tsx';
import { useAuth } from '@/auth.tsx';

type SearchProps = {
  q?: string;
  searchScope: SearchScope;
  mime: FileMimeEnum;
};

export const Route = createFileRoute('/_layout/search')({
  component: Search,
});

function Search() {
  const user = useAuth().user as User;
  const { q, searchScope, mime } = Route.useSearch() as SearchProps;
  const currentPageRef = useRef<number>(1);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [files, setFiles] = useState<RealFileModel[]>([]);
  const ocrOnly = searchScope === SearchScope.OCR;

  const [fetchFiles, { loading, error, data }] = useLazyQuery(SearchFilesDocument, {
    fetchPolicy: 'cache-first',
  });
  const departmentMap = user.assignments!.reduce(
    (result, { department }, _index) => {
      result[department.id] = department.name;
      return result;
    },
    {} as { [key: string]: string },
  );

  useEffect(() => {
    let ignore = false;

    const fetchData = async () => {
      const result = await fetchFiles({
        variables: {
          mime,
          q,
          ocrOnly,
          page: currentPageRef.current,
          take: TAKE_PER_PAGE,
        },
      });
      if (!ignore && result.data) {
        setFiles(
          fileModelToRealFileModel(result.data.searchFiles.items, departmentMap),
        );
        currentPageRef.current = 2;
      }
    };
    currentPageRef.current = 1;
    setIsLoadingMore(false);

    fetchData();

    return () => {
      ignore = true;
    };
  }, [q, mime, searchScope]);

  const loadMore = useCallback(async () => {
    if (isLoadingMore || !data?.searchFiles.pageInfo.hasNextPage) {
      return null;
    }
    setIsLoadingMore(true);
    const result = await fetchFiles({
      variables: {
        q,
        page: currentPageRef.current,
        take: TAKE_PER_PAGE,
        mime,
        ocrOnly,
      },
    });
    if (result.data?.searchFiles.items.length) {
      const newFiles = fileModelToRealFileModel(
        result.data.searchFiles.items,
        departmentMap,
      );
      setFiles(prev => [...prev, ...newFiles]);
      currentPageRef.current += 1;
    }
    setIsLoadingMore(false);
    return result;
  }, [
    data?.searchFiles.pageInfo.hasNextPage,
    searchScope,
    mime,
    fetchFiles,
    q,
    isLoadingMore,
  ]);

  const refetchFolders = useCallback(async () => {
    currentPageRef.current = 1;
    setIsLoadingMore(false);
    setFiles([]);
    const result = await fetchFiles({
      variables: {
        q,
        page: 1,
        take: TAKE_PER_PAGE,
        mime,
        ocrOnly,
      },
    });
    if (result.data) {
      setFiles(fileModelToRealFileModel(result.data.searchFiles.items, departmentMap));
      currentPageRef.current = 2;
    }
  }, [mime, searchScope, q]);

  if (loading && currentPageRef.current === 1) return null;
  if (error) return <div>Error: {error.message}</div>;

  const isDepartment = false;
  const folderName = '搜尋結果';

  return (
    <InfiniteScrollWrapper
      hasMore={data?.searchFiles?.pageInfo.hasNextPage || false}
      loadMore={loadMore}
      isLoading={isLoadingMore && currentPageRef.current !== 1}
    >
      <FolderView
        columns={['selector', 'name', 'size', 'departmentName', 'updatedAt']}
        folderName={folderName}
        isDepartment={isDepartment}
        breadcrumbs={[]}
        refetchFolder={refetchFolders}
        files={files}
      />
    </InfiniteScrollWrapper>
  );
}
