import React, { useState, useEffect, useCallback } from 'react';
import { useForm, useFieldArray, ControllerRenderProps } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '@/components/ui/button';
import { toast } from '@/components/ui/use-toast';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { cn } from '@/lib/utils';
import { PleadingDocument, PleadingModel } from '@@graphql';
import { useLazyQuery } from '@apollo/client';
import { getTaiwanDate } from '@/lib/utils';
import {
  AI_KEYWORDS,
  ANSWERS,
  FieldType,
  GarnishmentStatus,
  MANUAL_KEYWORDS,
  Question,
  QUESTIONS,
  SurveyData,
  surveySchema,
} from './shared';
import MergeColumns from './merge-columns';

type GeneratePleadingDialogProps = {
  fileId: string;
  createdAt: Date;
};

const GeneratePleadingDialog: React.FC<GeneratePleadingDialogProps> = ({
  fileId,
  createdAt,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState<Question | undefined>(
    undefined,
  );
  const [step, setStep] = useState<string | null>('q1');
  const [isMergeColumnsPage, setIsMergeColumnsPage] = useState<boolean>(false);
  const [isGen, setIsGen] = useState<boolean>(false);

  const [
    getPleading,
    { data: pleadingData, loading: isPleadingLoading, error: pleadingError },
  ] = useLazyQuery(PleadingDocument, {
    variables: {
      fileId,
    },
    fetchPolicy: 'network-only',
  });

  const pleading: PleadingModel = pleadingData?.pleading;

  const form = useForm<SurveyData>({
    defaultValues: {
      templateId: GarnishmentStatus.NOT_EMPLOYEE,
      answers: ANSWERS,
      AIKeywords: AI_KEYWORDS,
      manualKeywords: MANUAL_KEYWORDS,
    },
    resolver: zodResolver(surveySchema),
    mode: 'onChange',
  });

  const { watch, setValue, control } = form;
  const value = watch();

  const { fields: manualFields } = useFieldArray({
    control,
    name: 'manualKeywords',
  });

  const updateManualKeywordsValue = (label: string, value: string) => {
    const indexToUpdate = manualFields.findIndex(item => item.label === label);
    if (indexToUpdate !== -1) {
      setValue(`manualKeywords.${indexToUpdate}.value`, value);
    }
  };

  useEffect(() => {
    if (pleading) {
      const formattedCreatedAt = getTaiwanDate(createdAt);
      const manualKeywords = Object.values({
        ...Object.fromEntries(pleading.manualKeywords.map(k => [k.label, k])),
        createdDate: { label: 'createdDate', used: true, value: formattedCreatedAt },
        salaryDeductionDate: {
          label: 'salaryDeductionDate',
          used: true,
          value: formattedCreatedAt,
        },
        publishedDate: { label: 'publishedDate', used: true, value: getTaiwanDate() },
      });

      setValue('AIKeywords', pleading.AIKeywords);
      setValue('manualKeywords', manualKeywords);
      if (pleading.templateId) {
        setIsMergeColumnsPage(true);
        setValue('templateId', pleading.templateId);
      }
    }
  }, [createdAt, pleading, setValue]);

  const setTemplateValue = useCallback(
    (nextQuestion: Question) => {
      if (nextQuestion && nextQuestion.type === 'answer') {
        setValue('templateId', nextQuestion.template as string);
      }
    },
    [setValue],
  );

  useEffect(() => {
    const question = QUESTIONS.find((question: Question) => question.id === step);
    const nextQuestion = QUESTIONS.find(q => q.id === question?.next);
    setTemplateValue(nextQuestion as Question);
    setCurrentQuestion(question);
  }, [setTemplateValue, setValue, step, value.AIKeywords, value.manualKeywords]);

  useEffect(() => {
    if (currentQuestion?.type === 'answer') {
      setIsMergeColumnsPage(true);
    }
  }, [currentQuestion?.type]);

  const changeOption = useCallback(
    (optionValue: string) => {
      if (currentQuestion) {
        const { id, options } = currentQuestion;

        if (options.length !== 0) {
          setValue(`answers.${id}.ans`, optionValue);
          const option = options.find(option => option.value === value.answers[id].ans);
          const nextQuestion = QUESTIONS.find(q => q.id === option?.next);
          setTemplateValue(nextQuestion as Question);
          setIsGen(nextQuestion?.type === 'answer');
        }
      }
    },
    [currentQuestion, setTemplateValue, setValue, value.answers],
  );

  const getFormContent = useCallback(
    (field: ControllerRenderProps<SurveyData>) => {
      switch (currentQuestion?.type) {
        case FieldType.NUMBER_INPUT:
          return (
            <Input
              type="number"
              min="0"
              {...field}
              value={Number(field.value)}
              placeholder={currentQuestion?.placeholder}
              onChange={event => {
                field.onChange(+event.target.value);
              }}
            />
          );
        case FieldType.TEXT_INPUT:
          return (
            <Input
              {...field}
              value={String(field.value)}
              placeholder={currentQuestion?.placeholder}
            />
          );
        case FieldType.RADIO:
          return (
            <RadioGroup
              onValueChange={v => {
                changeOption(v);
              }}
              defaultValue={field.value as string}
              className="flex flex-col space-y-1"
            >
              <FormItem className="flex items-center space-x-3 space-y-0">
                <FormControl>
                  <RadioGroupItem value="true" />
                </FormControl>
                <FormLabel className="font-normal">是</FormLabel>
              </FormItem>
              <FormItem className="flex items-center space-x-3 space-y-0">
                <FormControl>
                  <RadioGroupItem value="false" />
                </FormControl>
                <FormLabel className="font-normal">否</FormLabel>
              </FormItem>
            </RadioGroup>
          );
      }
    },
    [changeOption, currentQuestion?.placeholder, currentQuestion?.type],
  );

  if (pleadingError) {
    toast({
      title: '請等候五秒再試',
      duration: 1500,
    });
    return;
  }

  if (!currentQuestion) return;

  const nextStep = () => {
    const { id, options, next } = currentQuestion;
    const question = QUESTIONS.find((question: Question) => question.id === step);

    switch (id) {
      case 'q3':
        updateManualKeywordsValue('monthlySalary', value.answers[id].ans as string);
        break;
      case 'q4':
        updateManualKeywordsValue('seizedCaseNumber', value.answers[id].ans as string);
        break;
      case 'q5':
        updateManualKeywordsValue(
          'seizedExecutionLocation',
          value.answers[id].ans as string,
        );
        break;
      default:
        break;
    }

    const nextQuestion = QUESTIONS.find(q => q.id === question?.next);
    if (nextQuestion && nextQuestion.type === 'answer') {
      setValue('templateId', nextQuestion.template as string);
    }

    if (options.length === 0) {
      setStep(next);
    } else {
      const option = options.find(option => option.value === value.answers[id].ans);
      if (!option) return;
      setStep(option.next);
    }
  };

  const prevStep = () => {
    const { id } = currentQuestion;
    setIsMergeColumnsPage(false);
    setStep(ANSWERS[id].prev);
  };

  return (
    <Dialog
      open={isOpen && !isPleadingLoading}
      onOpenChange={open => {
        // Reset to first step when dialog is closed
        setIsOpen(open);
        if (!open) setStep('q1');
      }}
    >
      <DialogTrigger asChild>
        <Button type="button" loading={isPleadingLoading} onClick={() => getPleading()}>
          生成書狀
        </Button>
      </DialogTrigger>
      <DialogContent
        className={cn('max-w-auto', isMergeColumnsPage && 'max-w-[1000px]')}
      >
        <DialogHeader>
          <DialogTitle>生成書狀</DialogTitle>
          <DialogDescription>透過 AI 協助貴公司快速生成書狀</DialogDescription>
        </DialogHeader>
        <Form {...form}>
          <form>
            {isMergeColumnsPage ? (
              <MergeColumns
                fileId={fileId}
                pleadingId={pleading?.id ?? ''}
                prevStep={prevStep}
                control={control}
                value={value}
                setValue={setValue}
              />
            ) : (
              <div>
                <div className="grid gap-4 p-3">
                  <FormField
                    control={control}
                    key={currentQuestion.id}
                    name={`answers.${currentQuestion.id}.ans`}
                    render={({ field }) => (
                      <FormItem className="space-y-3">
                        <FormLabel>{currentQuestion.title}</FormLabel>
                        <FormControl>{getFormContent(field)}</FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <div className="flex justify-between">
                    <div>
                      {step !== 'q1' && (
                        <Button type="button" onClick={prevStep}>
                          上一步
                        </Button>
                      )}
                    </div>
                    <div>
                      {isGen ? (
                        <Button type="button" onClick={nextStep}>
                          生成書狀
                        </Button>
                      ) : (
                        <Button
                          type="button"
                          onClick={nextStep}
                          disabled={!value.answers[currentQuestion.id].ans}
                        >
                          下一步
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default GeneratePleadingDialog;
