import { X } from 'lucide-react';
import { cn } from '@/lib/utils';
import type { PanelItem } from './';

type SidePanelProps = {
  isOpen: boolean;
  onClose: () => void;
  currentPanelId: string | null;
  panelData: PanelItem[];
};

export const SidePanel = ({
  isOpen,
  onClose,
  currentPanelId,
  panelData,
}: SidePanelProps) => (
  <div
    className={cn(
      'fixed top-[64px] right-0 w-[400px] h-[calc(100vh-64px)] bg-white',
      'transform transition-transform duration-300 ease-in-out',
      'border-l shadow-lg',
      isOpen ? 'translate-x-0' : 'translate-x-full',
    )}
  >
    <div className="border-b border-b-gray-200 p-4">
      <div className="flex items-center justify-between">
        <h2 className="text-lg font-semibold">
          {panelData.find(item => item.id === currentPanelId)?.title}
        </h2>
        <button className="cursor-pointer" onClick={onClose}>
          <X className="size-4.5 text-muted-foreground" strokeWidth={1.2} />
        </button>
      </div>
    </div>
    <div className="h-[calc(100vh-130px)] p-4  overflow-y-auto">
      {panelData.find(item => item.id === currentPanelId)?.content}
    </div>
  </div>
);
