import { createFileRoute } from '@tanstack/react-router';
import { useQuery } from '@apollo/client';
import { GetFileDocument } from '@@graphql';
import FileView from '@/pages/file-view';

export const Route = createFileRoute('/_layout/trash/$fileId')({
  component: FileComponent,
});

function FileComponent() {
  const { fileId } = Route.useParams();
  const { data, error, loading, refetch } = useQuery(GetFileDocument, {
    variables: { fileId },
    fetchPolicy: 'network-only',
  });

  if (loading) return null;
  if (error) return <div>Error: {error.message}</div>;
  if (!data?.file?.fileUrl) return <div>File not found</div>;

  return <FileView data={data?.file} refetchFile={refetch} fileId={fileId} />;
}
