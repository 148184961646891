import { useMutation } from '@apollo/client';
import { ToggleFilesPrivateDocument } from '@@graphql';
import { ActionButton, ActionProps } from '@/types/edit-action.ts';
import { useErrorHandler } from '@/hooks/useErrorHandler.tsx';
import { toast } from '@/components/ui/use-toast';
import { Lock } from 'lucide-react';
import { DialogClose, DialogDescription, DialogFooter } from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { ACTION_TYPE_TITLE } from '@/constants';

export const SetPrivateAction = ({
  selectedFileIds,
  refetchFolder,
  closeDialog,
  openDialog,
}: ActionProps): ActionButton => {
  const { handleError, resetErrors } = useErrorHandler({});
  const [setMutation] = useMutation(ToggleFilesPrivateDocument, {
    onError: err => handleError(err),
    onCompleted: async () => {
      refetchFolder && (await refetchFolder());
      toast({
        title: '已解除檔案機敏性',
        duration: 1000,
      });
      closeDialog();
    },
  });

  const handleSet = async () => {
    openDialog(
      '設定檔案機敏',
      <div>
        <DialogDescription className="text-sm">
          您確定要設定這些檔案為機敏檔案嗎？
        </DialogDescription>
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline" type="button">
              取消
            </Button>
          </DialogClose>
          <Button
            type="button"
            onClick={() => {
              resetErrors();
              setMutation({
                variables: { fileIds: selectedFileIds, isPrivate: true },
              });
            }}
          >
            確定
          </Button>
        </DialogFooter>
      </div>,
    );
  };

  return {
    title: ACTION_TYPE_TITLE.SET_PRIVATE,
    icon: <Lock />,
    onClick: handleSet,
  };
};
