import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { UserRoundPlus } from 'lucide-react';
import { Label } from './ui/label';
import { Input } from './ui/input';
import { CreateMfpAllowEmailDocument } from '@@graphql';
import { useMutation } from '@apollo/client';
import { FormEvent, useEffect, useState } from 'react';
import { toast } from '@/components/ui/use-toast';
import { useErrorHandler } from '@/hooks/useErrorHandler.tsx';
import { CreateMfpAllowEmailSchema } from '@/zodSchema/create-mfp-allow-email.ts';
import { Textarea } from '@/components/ui/textarea.tsx';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select.tsx';
import { AllowEmailType } from '@/types/allow-email.ts';

type DialogProps = {
  refetch: () => void;
};

type FormData = {
  email: string;
  description?: string;
  type: string;
};

const WhitelistDialog = ({ refetch }: DialogProps) => {
  const errorObject = {
    email: '',
    type: '',
  };
  const [open, setOpen] = useState(false);
  const { errors, handleError, resetErrors } = useErrorHandler(errorObject);

  useEffect(() => {
    resetErrors();
  }, [open]);

  const [createEmailMutation] = useMutation(CreateMfpAllowEmailDocument, {
    onCompleted: () => {
      refetch?.();
    },
  });

  const create = async (formData: FormData) => {
    await createEmailMutation({
      variables: {
        ...formData,
      },
    });
  };

  const onSubmitCreate = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    resetErrors();
    const data = new FormData(e.currentTarget);
    const emailValue = data.get('email') as string;
    const descriptionValue = data.get('description') as string | null;
    const typeValue = data.get('emailType') as string;
    const formData = {
      email: emailValue.toString().trim(),
      description: descriptionValue?.toString()?.trim(),
      type: typeValue,
    };
    try {
      CreateMfpAllowEmailSchema.parse(formData);
      await create(formData);
      toast({ title: '新增寄件人白名單成功', duration: 1500 });
      setOpen(false);
    } catch (err) {
      handleError(err);
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button className="gap-1 font-normal text-primary" variant="ghost">
          <UserRoundPlus size={20} strokeWidth={1.5} /> 新增白名單
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <form onSubmit={onSubmitCreate}>
          <DialogHeader>
            <DialogTitle>新增白名單</DialogTitle>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="email" className="text-right font-normal">
                電子郵件
              </Label>
              <div className="col-span-3">
                <Input id="email" type="email" name="email" />
                {errors.email && <div className="text-red-500">{errors.email}</div>}
              </div>
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="email" className="text-right font-normal">
                類型
              </Label>
              <div className="col-span-3">
                <Select name="emailType" defaultValue={AllowEmailType.MFP}>
                  <SelectTrigger>
                    <SelectValue placeholder="選擇類型" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value={AllowEmailType.MFP}>事務機</SelectItem>
                    <SelectItem value={AllowEmailType.EMAIL}>
                      {AllowEmailType.EMAIL}
                    </SelectItem>
                  </SelectContent>
                </Select>
                {errors.type && <div className="text-red-500">{errors.type}</div>}
              </div>
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="description" className="text-right font-normal">
                說明
              </Label>
              <Textarea id="description" name="description" className="col-span-3" />
            </div>
          </div>
          <DialogFooter>
            <DialogClose asChild>
              <Button variant="outline" type="button">
                取消
              </Button>
            </DialogClose>
            <Button type="submit">儲存</Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default WhitelistDialog;
