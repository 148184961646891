import SettingLayout from '@/components/layout/setting-layout';
import { createFileRoute } from '@tanstack/react-router';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import { ColumnDef } from '@tanstack/react-table';
import UserDialog from '@/components/user-dialog';
import { DialogActionType } from '@/constants';
import { ArrowUpDown, Check, Trash2 } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { DataTable } from '@/components/data-table';
import { toast } from '@/components/ui/use-toast';
import { Switch } from '@/components/ui/switch';
import { useMutation, useQuery } from '@apollo/client';
import {
  RemoveUserDocument,
  ToggleUserDocument,
  UserModel,
  UsersDocument,
} from '@@graphql';
import { useAuth } from '@/auth.tsx';
import { User } from '@/types';

enum ROLE {
  ADMIN = 'Admin',
  USER = 'User',
}

type UserData = {
  id: string;
  name: string;
  departments: string;
  email: string;
  isAdmin: boolean;
  enable: boolean;
  role: ROLE;
};

interface toggleUserProps {
  id: string;
  enabled: boolean;
}

export const Route = createFileRoute('/_layout/settings/user-settings')({
  component: UserSettings,
});

function UserSettings() {
  const currentUser = useAuth().user as User;
  const { data, error, loading, refetch } = useQuery(UsersDocument, {
    fetchPolicy: 'network-only',
  });
  const [toggleUserMutation] = useMutation(ToggleUserDocument, {
    onCompleted: async () => {
      await refetch();
    },
  });
  const [removeUserMutation] = useMutation(RemoveUserDocument, {
    onCompleted: async () => {
      await refetch();
    },
  });
  // loading and error handling
  if (loading) return null;
  if (error) return <div>Error: {error.message}</div>;
  const userData =
    data?.users
      .filter((user: UserModel) => user.id !== currentUser.id)
      .map(({ id, name, departments, isAdmin, email, enabled }: UserModel) => {
        return {
          id,
          name,
          departments: departments.map(({ name }: { name: string }) => name).join(', '),
          email,
          isAdmin,
          enable: enabled,
          role: isAdmin ? ROLE.ADMIN : ROLE.USER,
        } as UserData;
      }) ?? [];

  const toggleUser = async ({ id: userId, enabled }: toggleUserProps) => {
    await toggleUserMutation({
      variables: {
        updateUserId: userId,
        enabled,
      },
    });
  };

  const userColumns: ColumnDef<UserData>[] = [
    {
      accessorKey: 'name',
      header: '使用者名稱',
      cell: ({ row }) => row.getValue('name'),
    },
    {
      accessorKey: 'departments',
      header: ({ column }) => {
        return (
          <div
            className="flex items-center cursor-pointer"
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            部門名稱
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </div>
        );
      },
      cell: ({ row }) => row.getValue('departments') || '尚未指派',
    },
    {
      header: ({ column }) => {
        return (
          <div
            className="flex items-center cursor-pointer"
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            是否為公司管理者
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </div>
        );
      },
      accessorKey: 'isAdmin',
      cell: ({ row }) => (
        <div className="font-mono text-sm">
          {row.getValue('isAdmin') ? <Check /> : null}
        </div>
      ),
    },
    {
      accessorKey: 'email',
      header: ({ column }) => {
        return (
          <div
            className="flex items-center cursor-pointer"
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            電子郵件
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </div>
        );
      },
      cell: ({ row }) => (
        <div className="font-mono text-sm">{row.getValue('email')}</div>
      ),
    },
    {
      accessorKey: 'enable',
      header: ({ column }) => {
        return (
          <div
            className="flex items-center cursor-pointer"
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            是否啟用
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </div>
        );
      },
      cell: function Cell({ row }) {
        return (
          <Switch
            id="enable"
            defaultChecked={row.getValue('enable')}
            onCheckedChange={async isChecked => {
              await toggleUser({
                id: row.original.id,
                enabled: isChecked,
              });
              toast({
                title: isChecked ? '已啟用成功' : '已取消啟用成功',
                duration: 1500,
              });
            }}
          />
        );
      },
    },
    {
      id: 'edit',
      header: '編輯',
      enableHiding: false,
      cell: ({ row }) => {
        const { name, departments, email, isAdmin, id, role } = row.original;
        return (
          <UserDialog
            id={id}
            type={DialogActionType.EDIT}
            name={name}
            department={departments}
            isAdmin={isAdmin}
            email={email}
            role={role}
            refetch={refetch}
          />
        );
      },
    },
    {
      id: 'actions',
      header: '操作',
      enableHiding: false,
      cell: ({ row }) => {
        const { email } = row.original;
        return (
          <div>
            <AlertDialog>
              <AlertDialogTrigger asChild>
                <Button
                  className="size-8 text-error hover:text-error"
                  variant="ghost"
                  size="icon"
                >
                  <Trash2 size={20} strokeWidth={1.5} />
                </Button>
              </AlertDialogTrigger>
              <AlertDialogContent>
                <AlertDialogHeader>
                  <AlertDialogTitle>刪除使用者</AlertDialogTitle>
                  <AlertDialogDescription>
                    {`確定要刪除 ${email}`}
                  </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                  <AlertDialogCancel>取消</AlertDialogCancel>
                  <AlertDialogAction
                    onClick={async () => {
                      await removeUserMutation({
                        variables: {
                          removeUserId: row.original.id,
                        },
                      });
                      toast({
                        title: '已刪除成功',
                        duration: 1500,
                      });
                    }}
                  >
                    確定
                  </AlertDialogAction>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          </div>
        );
      },
    },
  ];
  return (
    <SettingLayout>
      <Card>
        <CardHeader>
          <div className="flex justify-between items-center">
            <CardTitle>使用者管理</CardTitle>
            <UserDialog type={DialogActionType.CREATE} refetch={refetch} />
          </div>
          <CardDescription>此頁面用來新增、編輯、刪除使用者</CardDescription>
        </CardHeader>
        <CardContent className="space-y-2">
          <DataTable data={userData} columns={userColumns} />
        </CardContent>
      </Card>
    </SettingLayout>
  );
}
