import { useFileMutation } from '@/tanstack';
import { ReactNode, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from '@/components/ui/use-toast';

const DropzoneArea = ({
  toFolderId,
  refetchFolder,
  children,
}: {
  toFolderId: string;
  refetchFolder?: () => Promise<void>;
  children: ReactNode;
}) => {
  const { mutate } = useFileMutation({
    method: 'POST',
    path: `/api/upload/${toFolderId}`,
    contentType: 'multipart/form-data',
  });

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      acceptedFiles.forEach(file => {
        const formData = new FormData();
        formData.append('file', file);
        toast({
          title: '檔案上傳中...',
          duration: 1500,
        });
        mutate(formData, {
          onSuccess: async () => {
            toast({
              title: '檔案上傳成功',
              duration: 1500,
            });
            if (refetchFolder) await refetchFolder();
          },
          onError: () => {
            toast({
              title: `${file.name} 上傳失敗`,
              duration: 1500,
            });
          },
        });
      });
    },
    [mutate],
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop, noClick: true });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {children}
    </div>
  );
};

export default DropzoneArea;
